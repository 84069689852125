import { Logo } from '@/components/Logo';
import { PlatformTypes } from '@/types/gql.generated';
import { Icon, Image, Text } from '@/ui';
import appleLogo from './icons/apple.png';
import EmailIcon from './icons/email.svg?react';
import googleLogo from './icons/google.png';
import officeLogo from './icons/office.png';
import outlookLogo from './icons/outlook.png';

export type Props = {
  platform: PlatformTypes;
};

export const PlatformIcon = ({ platform, ...ui }: Props) => {
  if (platform === PlatformTypes.AgendaHero) {
    return <Logo height={16} {...ui} />;
  }
  if (platform === PlatformTypes.AppleIcs) {
    return <Image h="19px" src={appleLogo} w="16px" {...ui} />;
  }
  if (platform === PlatformTypes.Email) {
    return <Icon as={EmailIcon} boxSize="16px" {...ui} />;
  }
  if (
    platform === PlatformTypes.GoogleIntegration ||
    platform === PlatformTypes.GoogleLink
  ) {
    return <Image boxSize="16px" src={googleLogo} {...ui} />;
  }
  if (platform === PlatformTypes.OfficeLink) {
    return <Image boxSize="16px" src={officeLogo} {...ui} />;
  }
  if (platform === PlatformTypes.OutlookLink) {
    return <Image boxSize="16px" src={outlookLogo} {...ui} />;
  }

  return (
    <Text
      fontSize="14px"
      left="-1px"
      pos="relative"
      w="16px"
      {...ui}
      lineHeight="1"
    >
      📆
    </Text>
  );
};
