import type { PageExperienceConfig } from '../types';
import banner from './banner.png';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'trinity-school',
  theme: {
    '--primary-color': '#00388C',
    '--secondary-color': '#f0f0f0',
  },
  header: {
    href: 'https://www.trinityschoolnyc.org',
    banner,
    logo,
  },
};
