import type { PageExperienceConfig } from '../types';
import banner from './banner.png';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'alighieri-dante-montessori-school',
  theme: {
    '--primary-color': '#57114C',
    '--secondary-color': '#f0f0f0',
  },
  header: {
    href: 'https://www.bpsmontessori.com',
    banner,
    logo,
  },
};
