import type { PageExperienceConfig } from '../types';
import banner from './banner.png';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'elixir-bar-events',
  hidePastEntries: false,
  theme: {
    '--primary-color': '#662154',
    '--secondary-color': '#F1E8D6',
  },
  header: {
    href: 'https://www.hearthmarketportsmouth.com/event-list',
    banner,
    logo,
  },
};
