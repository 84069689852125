import type { PageExperienceConfig } from '../types';
import banner from './banner.png';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'torq-sales-kickoff',
  theme: {
    '--primary-color': '#000000',
    '--secondary-color': '#ffffff',
  },
  header: {
    href: 'https://torq.io',
    banner,
    logo,
  },
};
