import type { PageExperienceConfig } from '../types';
import banner from './banner.png';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'bissellbrothers-events',
  hidePastEntries: false,
  theme: {
    '--primary-color': '#4eb126',
    '--secondary-color': '#dae9dd',
  },
  header: {
    href: 'https://bissellbrothers.com/events/',
    banner,
    logo,
  },
};
