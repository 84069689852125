import type { PageExperienceConfig } from '../types';
import banner from './banner.jpg';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'my-doula-village',
  primaryCta: 'subscribe',
  addToCalendar: 'none',
  isLive: true,
  hidePastEntries: false,
  theme: {
    '--primary-color': '#0A4207',
    '--secondary-color': '#ebeffc',
  },
  header: {
    href: 'https://doverdoula.com',
    banner,
    logo,
  },
  subscribe: {
    google:
      'https://calendar.google.com/calendar/render?cid=webcal%3A%2F%2Fagendahero.com%2Ffeeds%2Fprivate-024bd3b4-89b3-441d-8835-3397e2c3a86c',
    outlook:
      'https://outlook.live.com/owa/?path=/calendar/action/subscribe&url=https%3A%2F%2Fagendahero.com%2Ffeeds%2Fprivate-024bd3b4-89b3-441d-8835-3397e2c3a86c',
    apple:
      'webcal://agendahero.com/feeds/private-024bd3b4-89b3-441d-8835-3397e2c3a86c',
    other:
      'https://agendahero.com/feeds/private-024bd3b4-89b3-441d-8835-3397e2c3a86c',
  },
};
