import type { PageExperienceConfig } from '../types';
import banner from './banner.png';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'newmarket-rec',
  hidePastEntries: false,
  theme: {
    '--primary-color': '#00ade1',
    '--secondary-color': '#e8f4f8',
  },
  header: {
    href: 'https://newmarketrec.org/events',
    banner,
    logo,
  },
};
