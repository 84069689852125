import type { DateTime } from 'luxon';
import type { Weekday } from 'rrule';
import type { ParsedOptions } from 'rrule/dist/esm/types';
import type { DecoratedExperienceEvent } from '@/features/experiences/types';
import {
  type RecurrenceOption,
  RecurrenceSelect,
} from '@/pages/Schedule/components';
import { getDateSummary } from '@/pages/Schedule/utils';
import {
  Button,
  Flex,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/ui';
import { DateRangeInput } from './components/DateRangeInput';
import { TimeInput } from './components/TimeInput';

type Props = {
  magicEvent: DecoratedExperienceEvent;
  interval: ParsedOptions['interval'] | null;
  frequency: ParsedOptions['freq'] | null;
  byWeekday: Weekday[] | null;
  untilDate: DateTime | null;
  onRecurrenceChange: (option: RecurrenceOption) => void;
  onWeekdayChange: (weekday: Weekday[]) => void;
  onMonthChange: (weekday: Weekday | null) => void;
  onStartDateChange: (startDate: DateTime) => void;
  onEndDateChange: (endDate: DateTime) => void;
  onUntilDateChange: (untilDate: DateTime | null) => void;
  onAddTimeClick: () => void;
  onRemoveTimeClick: () => void;
  onStartTimeChange: (startTime: DateTime) => void;
  onEndTimeChange: (endTime: DateTime) => void;
};

export const DateInput = ({
  magicEvent,
  untilDate,
  byWeekday,
  frequency,
  interval,
  onRecurrenceChange,
  onMonthChange,
  onWeekdayChange,
  onEndDateChange,
  onStartDateChange,
  onUntilDateChange,
  onStartTimeChange,
  onEndTimeChange,
  onAddTimeClick,
  onRemoveTimeClick,
}: Props) => {
  const { startDate, endDate, isAllDay, rrule } = magicEvent;
  const summary = getDateSummary(startDate, endDate, isAllDay, rrule);

  return (
    <Flex align="center" gap="3">
      <Icon icon="CalendarBlank" size="20px" />

      <Popover placement="bottom-start">
        <PopoverTrigger>
          <Button
            _firstLetter={{ textTransform: 'capitalize' }}
            _hover={{ bg: 'gray.200' }}
            bg="gray.100"
            borderRadius="md"
            fontSize="md"
            h="auto"
            lineHeight="normal"
            px="2"
            py="1"
            variant="unstyled"
          >
            {summary}
          </Button>
        </PopoverTrigger>

        <PopoverContent
          borderRadius="md"
          boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.1)"
          display="flex"
          flexDir="column"
          gap="2"
          minW="470px"
          p="4"
        >
          <Flex align="center" gap="3">
            <Icon icon="Repeat" />

            <RecurrenceSelect
              borderColor="transparent"
              byWeekday={byWeekday}
              fontSize="md"
              frequency={frequency}
              interval={interval}
              size="sm"
              startDate={startDate}
              onChange={onRecurrenceChange}
              onMonthChange={onMonthChange}
              onWeekdayChange={onWeekdayChange}
            />
          </Flex>

          <Flex align="center" gap="3">
            <Icon icon="Calendar" />

            <DateRangeInput
              endDate={endDate}
              hasRecurrence={frequency !== null}
              isAllDay={isAllDay}
              startDate={startDate}
              untilDate={untilDate}
              onEndChange={onEndDateChange}
              onStartChange={onStartDateChange}
              onUntilChange={onUntilDateChange}
            />
          </Flex>

          <Flex align="center" gap="3">
            <Icon icon="Clock" />

            <TimeInput
              endDate={endDate}
              hasRecurrence={frequency !== null}
              isAllDay={isAllDay}
              startDate={startDate}
              onAddTimeClick={onAddTimeClick}
              onClose={() => null}
              onEndTimeChange={onEndTimeChange}
              onRemoveTimeClick={onRemoveTimeClick}
              onStartTimeChange={onStartTimeChange}
            />
          </Flex>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};
