import type { PageExperienceConfig } from '../types';
import banner from './banner.png';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'nfts-meet-the-industry-fall-2024',
  primaryCta: 'send-to-google',
  theme: {
    '--primary-color': '#000000',
    '--secondary-color': '#f0f0f0',
  },
  header: {
    href: 'https://nfts.co.uk',
    banner,
    logo,
  },
};
