import { useEffect } from 'react';
import { usePrevious } from 'react-use';
import { useCurrentUserContext } from '@/contexts/CurrentUserContext';
import { useQuickCreateContext } from '../contexts';

export const useResetOnLogout = () => {
  const { currentUser } = useCurrentUserContext();
  const { resetAppState } = useQuickCreateContext();
  const prevUser = usePrevious(currentUser);

  useEffect(() => {
    if (prevUser && !currentUser) {
      resetAppState();
    }
  }, [prevUser, currentUser, resetAppState]);
};
