import type { PageExperienceConfig } from '../types';
import banner from './banner.png';
import logo from './logo.jpg';

export const config: PageExperienceConfig = {
  slug: 'showcase-the-world',
  theme: {
    '--primary-color': '#324358',
    '--primary-color-foreground': '#ffffff',
    '--secondary-color': '#F7EEEA',
    '--secondary-color-foreground': '#324358',
  },
  header: {
    href: 'https://showcasetheworld.com',
    banner,
    logo,
  },
};
