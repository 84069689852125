import { useEffect, useRef } from 'react';
import { useQuickCreateContext } from '@/pages/QuickCreate/contexts';
import type { DecoratedMagicJob } from '@/pages/QuickCreate/types';

/** Hydrates the prompt from a bootstrapped job */
export const useHydratePrompt = (
  bootstrappedJob: DecoratedMagicJob | null | undefined
) => {
  const { setPromptFromJob } = useQuickCreateContext();
  const hydrated = useRef(false);

  // Restore the prompt if the page loads via a bootstrapped jobID
  useEffect(() => {
    if (bootstrappedJob && !hydrated.current) {
      setPromptFromJob(bootstrappedJob);
      hydrated.current = true;
    }
  }, [bootstrappedJob, setPromptFromJob]);
};
