import { Flex, type FlexProps, forwardRef } from '@/ui';

export type CardProps = FlexProps;

export const Card = forwardRef((props: FlexProps, ref) => {
  return (
    <Flex
      _focusVisible={{ outline: 'none', shadow: 'outline' }}
      bg="white"
      borderColor="rgba(0,0,0,0.15)"
      borderRadius="2xl"
      borderWidth="1px"
      direction="column"
      h="250px"
      overflow="hidden"
      px="5"
      py="4"
      ref={ref}
      transition="all 0.1s ease-in-out"
      _hover={{
        borderColor: 'rgba(0,0,0,0.2)',
        shadow: '0px 0px 10px 2px var(--chakra-colors-purple-50)',
      }}
      {...props}
    />
  );
});
