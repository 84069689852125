import { useQueryNormalizer } from '@normy/react-query';
import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient, queryClient } from '@/lib';
import {
  AddToCalendarConfigKeys,
  type AddToCalendarConfigMode,
  InteractionAppNames,
  type InteractionAppConfigInput,
} from '@/types/gql.generated';
import type { QueryError } from '@/utils/errors';
import { createExperienceQueryKey } from '@/utils/queryKeys';
import { usePageExperienceContext } from '../contexts/PageExperienceContext';
import type { PageExperience } from '../types';
import type {
  SetAddToCalendarModeMutation,
  SetAddToCalendarModeMutationVariables,
} from './useSetAddToCalendarMode.generated';

const query = gql`
  mutation SetAddToCalendarMode($input: InteractionAppConfigInput!) {
    configureInteractionApp(input: $input) {
      id
      applicationName
      config {
        setting
        value
      }
    }
  }
`;

export const useSetAddToCalendarMode = () => {
  const { experience } = usePageExperienceContext();
  const queryNormalizer = useQueryNormalizer();

  const { mutate, isPending } = useMutation<
    SetAddToCalendarModeMutation,
    QueryError,
    SetAddToCalendarModeMutationVariables
  >({
    mutationFn: (variables) => {
      return gqlClient.request<
        SetAddToCalendarModeMutation,
        SetAddToCalendarModeMutationVariables
      >(query, variables);
    },
    onSuccess: () => {
      if (experience) {
        queryClient.invalidateQueries({
          queryKey: createExperienceQueryKey(experience.id),
          exact: true,
        });
      }
    },
    onMutate: (variables) => {
      const { experienceId } = variables.input;
      const prevValue =
        queryNormalizer.getObjectById<PageExperience>(experienceId);

      return {
        rollbackData: {
          interactionApps: prevValue?.interactionApps,
        },
        optimisticData: {
          interactionApps: prevValue?.interactionApps.map((app) => {
            if (app.applicationName !== InteractionAppNames.AddToCalendar) {
              return app;
            }
            return {
              ...app,
              config: app.config.map((config) => {
                if (config.setting === AddToCalendarConfigKeys.Mode) {
                  return {
                    ...config,
                    value: variables.input.config[0].value,
                  };
                }
                return config;
              }),
            };
          }),
        },
      };
    },
  });

  /** Set an interaction for all events on the experience */
  const setAddToCalendarAppMode = useCallback(
    (
      value: AddToCalendarConfigMode,
      options?: MutateOptions<
        SetAddToCalendarModeMutation,
        QueryError,
        SetAddToCalendarModeMutationVariables
      >
    ) => {
      let interactionAppId: string | undefined = undefined;

      const interactionApp = experience?.interactionApps.find(
        (app) => app.applicationName === InteractionAppNames.AddToCalendar
      );
      if (interactionApp) {
        interactionAppId = interactionApp.id;
      } else {
        console.warn(
          '[configureInteractionApp] failed to find an interaction app'
        );
        return;
      }

      const input: InteractionAppConfigInput = {
        experienceId: experience!.id,
        interactionAppId,
        config: [
          {
            setting: AddToCalendarConfigKeys.Mode,
            value,
          },
        ],
      };
      mutate({ input }, options);
    },
    [mutate, experience]
  );

  return {
    setAddToCalendarAppMode,
    isPending,
  };
};
