import type { PageExperienceConfig } from '../types';
import banner from './banner.png';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'medallia-sales-kickoff',
  theme: {
    '--primary-color': '#4050c6',
    '--secondary-color': '#fafafa',
  },
  header: {
    href: 'https://www.medallia.com/',
    banner,
    logo,
  },
};
