import clsx from 'clsx';
import { Flex, Icon, type Icons } from '@/ui';
import classes from './OptionButtonStyles.module.css';

type Props = {
  isActive?: boolean;
  icon?: keyof typeof Icons | JSX.Element;
  label: string;
  onClick?: () => void;
};

export const OptionButton = ({ icon, label, isActive, onClick }: Props) => {
  return (
    <Flex
      align="center"
      as="button"
      borderRadius="2xl"
      borderWidth="1px"
      boxSize="98px"
      data-group
      direction="column"
      fontSize="xs"
      fontWeight="bold"
      gap="2"
      justify="center"
      lineHeight="1.1"
      p="2"
      transition="all 0.1s ease"
      _active={{
        transform: 'scale(0.98)',
      }}
      _hover={{
        bg: 'purple.50',
        borderColor: 'purple.500',
        color: 'purple.600',
      }}
      onClick={onClick}
      {...(isActive && {
        bg: 'purple.50',
        borderColor: 'purple.500',
        color: 'purple.600',
      })}
      className={clsx(classes.button, { active: isActive })}
    >
      {icon && typeof icon === 'string' ? (
        <Icon icon={icon} size="24px" />
      ) : (
        icon
      )}
      {label}
    </Flex>
  );
};
