import type { PageExperienceConfig } from '../types';
import banner from './banner.png';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'cougar-news-nov-2024',
  theme: {
    '--primary-color': '#00579F',
    '--secondary-color': '#F0F2F4',
  },
  header: {
    href: 'https://www.capousd.org',
    banner,
    logo,
  },
};
