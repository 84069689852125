import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import type { GetLibraryQuery } from '@/pages/Dashboard/hooks/useLibrary.generated';
import { createLibraryQueryKey } from '@/utils/queryKeys';

const queryKey = createLibraryQueryKey();

export const useLibraryCache = () => {
  const queryClient = useQueryClient();

  const removeLibraryItem = useCallback(
    (id: string) => {
      queryClient.setQueryData<GetLibraryQuery | undefined>(
        queryKey,
        (oldData) => {
          if (!oldData) {
            return oldData;
          }
          return {
            ...oldData,
            getLibrary: oldData.getLibrary.filter((item) => item.id !== id),
          };
        }
      );
    },
    [queryClient]
  );

  return {
    removeLibraryItem,
  };
};
