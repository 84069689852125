import { useState } from 'react';
import { AppLoader } from '@/components/AppLoader';
import { ConfirmDialog, ConfirmDialogTitle } from '@/components/ConfirmDialog';
import { QuickCreateErrorMessage } from '@/components/QuickCreateErrorMessage';
import { useAnalytics } from '@/hooks/useAnalytics';
import { usePageTitle } from '@/hooks/usePageTitle';
import { MagicJobSource } from '@/types/gql.generated';
import { MotionFlex, Flex, useToast } from '@/ui';
import { type QueryError, isGQLErrorOfType } from '@/utils/errors';
import { AuthModal } from '../../components/AuthModal';
import { CenteredContent } from '../../components/CenteredContent';
import { EntryList } from '../../components/EntryList';
import { Layout } from '../../components/Layout';
import { LoadingCard } from '../../components/LoadingCard';
import { NotFound } from '../../components/NotFound';
import { PromptForm } from '../../components/PromptForm';
import { ResultsFeedbackForm } from '../../components/ResultsFeedbackForm';
import { useQuickCreateContext } from '../../contexts';
import { useResetOnLogout } from '../../hooks/useResetOnLogout';
import type { DecoratedMagicEvent } from '../../types';
import { Hero } from './components/Hero';
import {
  InvalidEmailJobNotice,
  isInvalidEmailJobStatus,
} from './components/InvalidEmailJobNotice';
import {
  COLUMN_LAYOUT_BREAKPOINT,
  useIsColumnLayout,
} from './hooks/useIsColumnLayout';
import { useScrollToResults } from './hooks/useScrollToResults';

const COLUMN_SPACING = {
  [COLUMN_LAYOUT_BREAKPOINT]: 8,
};

type Props = {
  bootstrappedJobLoading: boolean;
  bootstrappedJobError: QueryError | null;
};

export const Home = ({
  bootstrappedJobLoading,
  bootstrappedJobError,
}: Props) => {
  const {
    job,
    state,
    prompt,
    createEntries,
    editorRef,
    deleteMutation,
    deleteEntry,
    resetAppState,
  } = useQuickCreateContext();
  const isAppSource = job?.source === MagicJobSource.App;
  const { isColumnLayout } = useIsColumnLayout();
  const { trackEvent } = useAnalytics();
  const toast = useToast();
  const [confirmDeleteEntry, setConfirmDeleteEntry] =
    useState<DecoratedMagicEvent | null>(null);

  const { setResultsContainer } = useScrollToResults();
  useResetOnLogout();
  usePageTitle('Magic');

  const handleCreateSubmit = () => {
    createEntries(prompt, {
      onError: () => editorRef.current?.commands.focus(),
    });

    editorRef.current?.commands.blur();
  };

  const handleDelete = () => {
    if (confirmDeleteEntry) {
      deleteEntry(confirmDeleteEntry.id, {
        onError: () => toast.error('Error deleting event'),
        onSettled: () => setConfirmDeleteEntry(null),
        onSuccess: () => {
          // reset if this is the last entry
          if (job?.events.length === 1) {
            resetAppState();
          } else {
            setConfirmDeleteEntry(null);
          }
        },
      });

      trackEvent('quick-create:click confirm delete entry');
    }
  };

  return (
    <>
      <AuthModal />

      <ConfirmDialog
        isLoading={deleteMutation.isPending}
        isOpen={!!confirmDeleteEntry}
        messageProps={{ px: 8 }}
        message={
          job?.events.length === 1
            ? 'This is your last event! Deleting it will clear your results.'
            : undefined
        }
        title={
          <ConfirmDialogTitle>
            Delete <em>{confirmDeleteEntry?.title ?? 'Untitled event'}</em>?
          </ConfirmDialogTitle>
        }
        onCancel={() => setConfirmDeleteEntry(null)}
        onConfirm={handleDelete}
      />

      <Layout>
        <Hero hideTagLine={bootstrappedJobLoading || isAppSource} mb="8" />

        <CenteredContent
          animate={state === 'default' ? 'default' : 'results'}
          gap="6"
          initial="default"
          maxW="var(--chakra-breakpoints-magic-max-width)"
          variants={{ default: {}, results: {} }}
        >
          {bootstrappedJobLoading ? (
            <AppLoader />
          ) : isGQLErrorOfType(
              bootstrappedJobError,
              'QuickEntriesJobNotFound'
            ) ? (
            <NotFound />
          ) : bootstrappedJobError ? (
            <QuickCreateErrorMessage error={bootstrappedJobError} />
          ) : job?.source == MagicJobSource.Email &&
            isInvalidEmailJobStatus(job?.status) ? (
            <InvalidEmailJobNotice status={job.status} />
          ) : (
            <MotionFlex
              direction="column"
              gap={state === 'edit' ? 4 : 2}
              {...(!isColumnLayout && {
                direction: 'row',
                gap: 0,
              })}
            >
              <PromptForm
                alignSelf="flex-start"
                editorRef={editorRef}
                flex="1"
                // key ensures that the animations function correctly if the user's
                // window is resized across the isColumnLayout breakpoint
                key={String(isColumnLayout)}
                pr={state !== 'default' ? COLUMN_SPACING : undefined}
                width="100%"
                onSubmit={handleCreateSubmit}
                {...(!isColumnLayout && {
                  maxW: '50%',
                  position: 'sticky',
                  top: 4,
                  variants: {
                    default: { translateX: '50%' },
                    results: { translateX: 0 },
                  },
                })}
              />

              {state !== 'default' && (
                <Flex align="center" direction="column" flex="1">
                  <Flex
                    borderColor="gray.400"
                    borderLeftWidth={isColumnLayout ? undefined : '1px'}
                    direction="column"
                    h={state === 'loading' ? '100%' : 'auto'}
                    pl={COLUMN_SPACING}
                    ref={setResultsContainer}
                    scrollMarginTop="8"
                    w="100%"
                  >
                    {state === 'loading' ? (
                      <LoadingCard />
                    ) : state === 'edit' ? (
                      <EntryList
                        confirmDeleteEntry={confirmDeleteEntry}
                        onDeleteClick={setConfirmDeleteEntry}
                      />
                    ) : null}
                  </Flex>

                  {state === 'edit' && (
                    <ResultsFeedbackForm mt="8" pl={COLUMN_SPACING} />
                  )}
                </Flex>
              )}
            </MotionFlex>
          )}
        </CenteredContent>
      </Layout>
    </>
  );
};
