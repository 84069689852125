import { AppLoader } from '@/components/AppLoader';
import { useLauncherAction } from '@/hooks/useLauncher';
import { usePageTitle } from '@/hooks/usePageTitle';
import { Flex } from '@/ui';
import {
  AddContentModal,
  useAddContentModalStore,
} from '../../components/AddContentModal';
import { EditEventModal } from '../../components/EditEventModal';
import { PageExperience } from '../../components/PageExperience';
import { usePageExperienceContext } from '../../contexts/PageExperienceContext';
import { Controls } from './components/Controls';
import { PageHeader } from './components/PageHeader';

export const EditPageExperience = () => {
  const { open } = useAddContentModalStore();
  const { experience, experienceQuery } = usePageExperienceContext();

  useLauncherAction('experience:onboarding', () => {
    open({ launchState: 'onboarding' });
  });

  usePageTitle(experience?.title);

  return (
    <Flex
      flex="1"
      flexDir="column"
      sx={{
        opacity: 1,
        transition: 'opacity 0.5s ease',
        '@starting-style': {
          opacity: 0,
        },
      }}
    >
      <AddContentModal />
      <PageHeader />
      <Controls onAddContentClick={open} />
      <EditEventModal />

      {experienceQuery.isLoading ? <AppLoader /> : <PageExperience />}
    </Flex>
  );
};
