import type { PageExperienceConfig } from '../types';
import banner from './banner.png';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'menlo-enotes-feedback',
  hidePastEntries: true,
  theme: {
    '--primary-color': '#215da5',
    '--secondary-color': '#DEEFF0',
  },
  header: {
    href: 'https://www.menloschool.org',
    banner,
    logo,
  },
};
