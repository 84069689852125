import { FormControl, FormLabel, Icon, type Icons, Switch, useId } from '@/ui';

type Props = {
  icon: keyof typeof Icons;
  label: string;
  isChecked?: boolean;
  onChange?: () => void;
};

export const OptionToggle = ({ icon, label, isChecked, onChange }: Props) => {
  const id = useId();

  return (
    <FormControl alignItems="center" display="flex" gap="2">
      <Icon color="gray.500" icon={icon} size="18px" />
      <FormLabel fontWeight="medium" htmlFor={id} lineHeight="normal" mb="0">
        {label}
      </FormLabel>
      <Switch
        colorScheme="purple"
        id={id}
        isChecked={isChecked}
        ml="auto"
        onChange={onChange}
      />
    </FormControl>
  );
};
