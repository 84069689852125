import type { PageExperienceConfig } from '../types';
import banner from './banner.png';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'durham-friday-updates',
  primaryCta: 'subscribe',
  isLive: true,
  hidePastEntries: true,
  theme: {
    '--primary-color': '#173A5F',
    '--secondary-color': '#E1E6E7',
  },
  header: {
    href: 'https://www.ci.durham.nh.us/fridayupdate/friday-updates-november-8-2024',
    banner,
    logo,
  },
  subscribe: {
    google:
      'https://calendar.google.com/calendar/render?cid=webcal%3A%2F%2Fagendahero.com%2Ffeeds%2Fprivate-0407a86d-9d82-4804-902b-a6adf274e7b2',
    outlook:
      'https://outlook.live.com/owa/?path=/calendar/action/subscribe&url=https%3A%2F%2Fagendahero.com%2Ffeeds%2Fprivate-0407a86d-9d82-4804-902b-a6adf274e7b2',
    apple:
      'webcal://agendahero.com/feeds/private-0407a86d-9d82-4804-902b-a6adf274e7b2',
    other:
      'https://agendahero.com/feeds/private-0407a86d-9d82-4804-902b-a6adf274e7b2',
  },
};
