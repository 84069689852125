import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useNavigateToSchedule } from '@/hooks/useNavigateToSchedule';
import {
  useScheduleTemplates,
  type ScheduleTemplate,
} from '@/hooks/useScheduleTemplates';
import { useAddContentModalStore } from '@/pages/Schedule/components/AddContentModal';
import { useOnboardingModalStore } from '@/pages/Schedule/components/OnboardingModal';
import { ScheduleType } from '@/types/gql.generated';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  type ModalProps,
} from '@/ui';
import { IndexState } from './components/IndexState';
import { LoadingState } from './components/LoadingState';
import { TemplateState } from './components/TemplateState';
import { getVariablesForOption } from './createLib';
import { useCreateScheduleWrapper } from './hooks/useCreateScheduleWrapper';
import { useCreateScheduleModalStore } from './store';
import type { ScheduleOption } from './types';

const variants = {
  enter: {
    opacity: 1,
    transition: { duration: 0.1 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.1 },
  },
};

type Props = Pick<ModalProps, 'onCloseComplete'>;

export const CreateScheduleModal = ({ onCloseComplete }: Props) => {
  const { isOpen, close } = useCreateScheduleModalStore();
  const { navigateToSchedule } = useNavigateToSchedule();
  const { trackEvent } = useAnalytics();
  const {
    createSchedule,
    createScheduleFromTemplate,
    createFamilySchedule,
    debouncedIsPending,
    debouncedIsSuccess,
  } = useCreateScheduleWrapper();
  const { open: openAddContentModal } = useAddContentModalStore();
  const { open: openOnboardingModal } = useOnboardingModalStore();
  const { templates } = useScheduleTemplates();
  const [scheduleOption, setScheduleOption] = useState<ScheduleOption | null>(
    null
  );
  const [scheduleTemplate, setScheduleTemplate] = useState<
    ScheduleTemplate | undefined
  >(undefined);

  // after we've navigated and after the artificial delay on isSuccess,
  // launch into the next workflow based on the selected option
  useEffect(() => {
    if (!debouncedIsSuccess) {
      return;
    }
    if (scheduleOption === 'blank') {
      openAddContentModal({
        launchState: 'create-schedule',
      });
    } else if (scheduleOption === 'template') {
      openAddContentModal({
        launchState: 'create-schedule-from-template',
        headerIcons: scheduleTemplate?.headerIcons,
      });
    } else {
      openOnboardingModal({
        flow: 'family',
      });
    }

    close();
  }, [
    debouncedIsSuccess,
    close,
    scheduleOption,
    openAddContentModal,
    openOnboardingModal,
    scheduleTemplate,
  ]);

  const handleCreate = (option: ScheduleOption) => {
    const type = ScheduleType.Classic;
    trackEvent('schedule:create', { type, workflow: option });
    setScheduleOption(option);

    if (option === 'family') {
      createFamilySchedule({
        onSuccess: (schedule) => {
          navigateToSchedule(schedule, {
            state: {
              launch: 'onboarding',
            },
          });
        },
      });
    } else {
      createSchedule(
        { ...getVariablesForOption(option), type },
        {
          onSuccess: (schedule) => {
            navigateToSchedule(schedule);
          },
        }
      );
    }
  };

  const handleCreateFromTemplate = (template: ScheduleTemplate) => {
    setScheduleTemplate(template);
    trackEvent('schedule:create', {
      type: ScheduleType.Classic,
      workflow: 'template',
    });
    return createScheduleFromTemplate(
      { templateId: template.id },
      {
        onSuccess: (schedule) => navigateToSchedule(schedule),
      }
    );
  };

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      returnFocusOnClose={false}
      variant="primary"
      onClose={close}
      onCloseComplete={onCloseComplete}
    >
      <ModalContent
        bg="#F5EFFB"
        minH={{ base: 'var(--app-height)', md: '665px' }}
        minW={{ base: '100vw', md: '750px' }}
        overflow="hidden"
        pos="relative"
      >
        <ModalCloseButton zIndex="1" />
        <ModalBody
          display="flex"
          flexDirection="column"
          p={scheduleOption === 'template' ? '0' : undefined}
          pos="relative"
        >
          <AnimatePresence mode="popLayout">
            {debouncedIsPending || debouncedIsSuccess ? (
              <LoadingState
                animate="enter"
                exit="exit"
                initial="enter"
                key="loading"
                variants={variants}
              />
            ) : scheduleOption === 'template' ? (
              <TemplateState
                animate="enter"
                exit="exit"
                initial="enter"
                key="template"
                templates={templates}
                variants={variants}
                onReset={() => setScheduleOption(null)}
                onSelect={(template) => handleCreateFromTemplate(template)}
              />
            ) : (
              <IndexState
                animate="enter"
                exit="exit"
                initial="exit"
                key="index"
                variants={variants}
                onCreate={handleCreate}
                onOpenTemplates={() => setScheduleOption('template')}
              />
            )}
          </AnimatePresence>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
