import { useEffect, useState } from 'react';
import type { QueryError } from '@/utils/errors';
import { useQuickCreateContext } from '../../contexts';
import { QuickCreateContextProvider } from '../../contexts/QuickCreateContext';
import type { DecoratedMagicJob } from '../../types';
import { Home } from './Home';
import { useGetBootstrappedJob } from './hooks/useGetBootstrappedJob';
import { useHydratePrompt } from './hooks/useHydratePrompt';

type Props = {
  bootstrappedJob: DecoratedMagicJob | undefined | null;
  bootstrappedJobLoading: boolean;
  bootstrappedJobError: QueryError | null;
  bootstrappedJobId: string | undefined;
};

const useBoostrappedJobLoading = ({
  bootstrappedJobId,
  bootstrappedJobError,
  bootstrappedJobLoading,
}: Props) => {
  const { job } = useQuickCreateContext();
  const [loaded, setLoaded] = useState(false);

  // if we're loading a bootstrapped job, keep the loading state true
  // until the job has propagated through the context to avoid jank
  let isLoading = bootstrappedJobLoading;
  if (!loaded && bootstrappedJobId && !bootstrappedJobError) {
    isLoading = !job;
  }

  // ensure that once the bootstrapped job has finished loading, we don't
  // show the loading indicator anymore when a new job is run
  useEffect(() => {
    if (job && bootstrappedJobId && job.id === bootstrappedJobId) {
      setLoaded(true);
    }
  }, [job, bootstrappedJobId]);

  return { bootstrappedJobLoading: isLoading };
};

const HomeViewLoaderWithContext = (props: Props) => {
  const { bootstrappedJobLoading } = useBoostrappedJobLoading(props);
  useHydratePrompt(props.bootstrappedJob);

  return <Home {...props} bootstrappedJobLoading={bootstrappedJobLoading} />;
};

export const HomeLoader = () => {
  const { job, bootstrappedJobId, error, isLoading, reset } =
    useGetBootstrappedJob();

  return (
    <QuickCreateContextProvider job={job} onReset={reset}>
      <HomeViewLoaderWithContext
        bootstrappedJob={job}
        bootstrappedJobError={error}
        bootstrappedJobId={bootstrappedJobId}
        bootstrappedJobLoading={isLoading}
      />
    </QuickCreateContextProvider>
  );
};
