import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import type { GetExperienceInput } from '@/types/gql.generated';
import type { QueryError } from '@/utils/errors';
import { createExperienceQueryKey } from '@/utils/queryKeys';
import { PageExperienceFragment } from '../fragments/PageExperience';
import type { PageExperience } from '../types';
import type {
  GetPageExperienceQuery,
  GetPageExperienceQueryVariables,
} from './useGetPageExperience.generated';

const query = gql`
  ${PageExperienceFragment}
  query GetPageExperience($input: GetExperienceInput!) {
    getExperience(input: $input) {
      ...PageExperience
    }
  }
`;

const selector = (data: GetPageExperienceQuery): PageExperience => {
  return data.getExperience;
};

export const useGetPageExperience = (experienceId: string | undefined) => {
  const queryKey = createExperienceQueryKey(experienceId);

  return useQuery<GetPageExperienceQuery, QueryError, PageExperience>({
    enabled: Boolean(experienceId),
    queryKey,
    select: selector,
    queryFn: () => {
      const input: GetExperienceInput = {
        experienceId: experienceId!, // protected by the `enabled` setting
      };
      return gqlClient.request<
        GetPageExperienceQuery,
        GetPageExperienceQueryVariables
      >(query, { input });
    },
  });
};
