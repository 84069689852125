import { MagicJobStatus } from '@/types/gql.generated';

const INVALID_EMAIL_JOB_STATUSES = [
  MagicJobStatus.Waiting,
  MagicJobStatus.Active,
  MagicJobStatus.Failed,
] as const;

export type InvalidEmailJobStatus = (typeof INVALID_EMAIL_JOB_STATUSES)[number];

export const isInvalidEmailJobStatus = (
  status: MagicJobStatus | undefined
): status is InvalidEmailJobStatus => {
  return (
    !!status &&
    INVALID_EMAIL_JOB_STATUSES.includes(status as InvalidEmailJobStatus)
  );
};
