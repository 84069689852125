import { TIME_ZONE_UTC } from '@/constants';
import { createEntryDate, getLocalTimeZone } from '@/utils/dates';
import type { MagicEventFragment } from '../fragments/MagicEventFragment.generated';
import type { MagicJobFragment } from '../fragments/MagicJobFragment.generated';
import type { DecoratedMagicEvent, DecoratedMagicJob } from '../types';

export const createDecoratedMagicEvent = (
  rawEvent: MagicEventFragment
): DecoratedMagicEvent => {
  const localTimeZone = getLocalTimeZone();

  return {
    ...rawEvent,
    timeZone: rawEvent.isAllDay
      ? TIME_ZONE_UTC
      : (rawEvent.start.timeZone ?? localTimeZone),
    startDate: createEntryDate(
      rawEvent.isAllDay ? rawEvent.start.date! : rawEvent.start.dateTime!,
      rawEvent.start.timeZone!,
      localTimeZone,
      rawEvent.isAllDay
    ),
    endDate: createEntryDate(
      rawEvent.isAllDay ? rawEvent.end.date! : rawEvent.end.dateTime!,
      rawEvent.end.timeZone!,
      localTimeZone,
      rawEvent.isAllDay
    ),
  };
};

export const createDecoratedMagicJob = (
  job: MagicJobFragment
): DecoratedMagicJob => {
  return {
    ...job,
    events: job.events.map(createDecoratedMagicEvent),
  };
};
