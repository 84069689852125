import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useCreateSchedule } from '@/hooks/useCreateSchedule';
import { useCreateScheduleFromTemplate } from '@/hooks/useCreateScheduleFromTemplate';
import { useDebounceLoading } from '@/hooks/useDebounceLoading';

export const useCreateScheduleWrapper = () => {
  const {
    createSchedule: createStandard,
    createFamilySchedule,
    data: standardSchedule,
    isPending: isStandardPending,
    isSuccess: isStandardSuccess,
  } = useCreateSchedule();

  const {
    createScheduleFromTemplate,
    data: scheduleFromTemplate,
    isPending: isTemplatePending,
    isSuccess: isTemplateSuccess,
  } = useCreateScheduleFromTemplate();

  const schedule = standardSchedule || scheduleFromTemplate;
  const isPending = isStandardPending || isTemplatePending;
  const isSuccess = isStandardSuccess || isTemplateSuccess;

  const [didNavigate, setDidNavigate] = useState(false);
  const [debouncedIsSuccess, setDebouncedIsSuccess] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (isSuccess) {
      const timerId = setTimeout(() => setDebouncedIsSuccess(true), 500);
      return () => clearTimeout(timerId);
    }
  }, [isSuccess]);

  const debouncedIsPending = useDebounceLoading(
    isPending || (isSuccess && !debouncedIsSuccess),
    200
  );

  useEffect(() => {
    if (schedule && location.pathname.includes(schedule.id)) {
      setDidNavigate(true);
    }
  }, [location, schedule, isPending, didNavigate]);

  return {
    createSchedule: createStandard,
    createScheduleFromTemplate,
    createFamilySchedule,
    debouncedIsPending,
    debouncedIsSuccess,
  };
};
