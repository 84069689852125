import type { PageExperienceConfig } from '../types';
import banner from './banner.png';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'throwback-brewery-events',
  theme: {
    '--primary-color': '#282726',
    '--secondary-color': '#f6f0e7',
  },
  header: {
    href: 'https://www.throwbackbrewery.com',
    banner,
    logo,
  },
};
