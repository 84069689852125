import { useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback, useRef, useState } from 'react';
import { gqlClient } from '@/lib/gql-client';
import type { DecoratedMagicJob, Prompt } from '@/pages/QuickCreate/types';
import { createDecoratedMagicJob } from '@/pages/QuickCreate/utils/createDecoratedMagicEvent';
import {
  type Base64FileInput,
  type CreateMagicJobFrom,
  MagicJobSource,
  MagicJobType,
} from '@/types/gql.generated';
import { getLocalTimeZone } from '@/utils/dates';
import type { QueryError } from '@/utils/errors';
import { toBase64 } from '@/utils/file';
import type {
  CreateMagicJobMutation,
  CreateMagicJobMutationVariables,
} from './useCreateMagicJob.generated';
import { usePollMagicJobStatus } from './usePollMagicJobStatus';

type Options = {
  onSuccess?: (job: DecoratedMagicJob) => void;
  onError?: (err: Error | QueryError) => void;
};

const query = gql`
  mutation CreateMagicJob($input: CreateMagicJobInput!) {
    createMagicJob(input: $input) {
      id
    }
  }
`;

export const useCreateMagicJob = (promptType: MagicJobType) => {
  const pollQuery = usePollMagicJobStatus();
  const [isPending, setPending] = useState(false);
  const canceledRef = useRef(false);

  const mutation = useMutation<
    string,
    QueryError,
    Omit<CreateMagicJobMutationVariables, 'version'>
  >({
    mutationFn: async (variables) => {
      const result = await gqlClient.request<
        CreateMagicJobMutation,
        CreateMagicJobMutationVariables
      >(query, variables);

      return result.createMagicJob.id;
    },
  });

  const createMagicJob = useCallback(
    async (prompt: Prompt, instructions: string, options?: Options) => {
      const timeZone = getLocalTimeZone();

      const onCreateJobSuccess = async (jobId: string) => {
        if (canceledRef.current) {
          // job was canceled in between creating the job and polling its status.
          // this is unlikely to happen but we should handle it to prevent
          // unnecessary polling.
          return;
        }
        if (!jobId) {
          return;
        }
        try {
          const job = await pollQuery.pollUntilJobComplete(jobId);
          const decoratedJob = createDecoratedMagicJob(job);

          if (!canceledRef.current) {
            // job was canceled while polling - ignore the response
            options?.onSuccess?.(decoratedJob);
          }
        } catch (err) {
          if (!canceledRef.current) {
            // job was canceled while polling - ignore the error
            options?.onError?.(err as Error | QueryError);
          }
        } finally {
          setPending(false);
        }
      };

      const onCreateJobError = (err: QueryError) => {
        if (!canceledRef.current) {
          options?.onError?.(err);
          setPending(false);
        }
      };

      const mutateOptions = {
        onSuccess: onCreateJobSuccess,
        onError: onCreateJobError,
      };

      setPending(true);
      canceledRef.current = false;

      let from: CreateMagicJobFrom;

      if (promptType === MagicJobType.File && prompt.file instanceof File) {
        const data = await toBase64(prompt.file);
        const mimetype = prompt.file.type;

        const base64File: Base64FileInput = {
          mimetype,
          filename: prompt.file.name,
          data: data.replace(`data:${mimetype};base64,`, ''),
        };
        from = { base64File };
      } else if (prompt.base64) {
        const base64File: Base64FileInput = {
          data: prompt.base64,
          filename: 'screenshot.png',
          mimetype: 'image/png',
        };
        from = { base64File };
      } else {
        from = { text: prompt.html };
      }

      mutation.mutate(
        {
          input: {
            from,
            source: MagicJobSource.Web,
            prompt: instructions,
            timeZone,
          },
        },
        mutateOptions
      );
    },
    [mutation, promptType, pollQuery]
  );

  const isError = mutation.isError || !!pollQuery.error;
  const error = mutation.error || pollQuery.error;

  const reset = () => {
    canceledRef.current = true;
    setPending(false);
    pollQuery.reset();
    mutation.reset();
  };

  return { createMagicJob, reset, isPending, isError, error };
};
