import type { ComponentProps } from 'react';
import { useCurrentUserContext } from '@/contexts/CurrentUserContext';
import { Header } from '@/pages/QuickCreate/components/Header';
import { Heading } from '@/pages/QuickCreate/components/Heading';
import { useQuickCreateContext } from '@/pages/QuickCreate/contexts';
import { Text } from '@/ui';

type Props = ComponentProps<typeof Header> & {
  hideTagLine: boolean;
};

export const Hero = ({ hideTagLine, ...props }: Props) => {
  const { isAuthenticated } = useCurrentUserContext();
  const { job } = useQuickCreateContext();

  if (hideTagLine) {
    return <Header {...props} />;
  }

  if (!isAuthenticated) {
    return (
      <Header {...props}>
        <Heading>
          Turn text (and images!)
          <br />
          into calendar events
        </Heading>
        <Text mt="3">
          💡 <strong>Tip:</strong> you can ask for multiple events!
        </Text>
      </Header>
    );
  }

  const events = job?.events ?? [];
  if (events.length > 0) {
    const message =
      events.length === 1
        ? 'Event is ready for your review'
        : 'Events are ready for your review';

    return (
      <Header {...props}>
        <Heading>Voila!</Heading>
        <Text fontSize="22px" fontWeight="medium" mt="3">
          {message}
        </Text>
      </Header>
    );
  }

  return (
    <Header {...props}>
      <Heading>
        What do you want on
        <br />
        your calendar?
      </Heading>
    </Header>
  );
};
