import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import type { DateTime } from 'luxon';
import { useCallback } from 'react';
import { gqlClient } from '@/lib';
import { usePlatformExports } from '@/pages/QuickCreate/hooks/usePlatformExports';
import { getEventLink } from '@/pages/QuickCreate/utils/googleCalendar';
import { PlatformTypes } from '@/types/gql.generated';
import type { QueryError } from '@/utils/errors';
import type { Destination } from '../types';
import type {
  ExportQuickEntriesMutation,
  ExportQuickEntriesMutationVariables,
} from './useLegacyExportMagicEvents.generated';

type MagicEventLike = {
  id: string;
  startDate: DateTime;
};

const query = gql`
  mutation ExportQuickEntries(
    $integrationId: ID!
    $quickEntryIds: [ID!]!
    $calendarId: String!
  ) {
    exportQuickEntries(
      integrationId: $integrationId
      quickEntryIds: $quickEntryIds
      calendarId: $calendarId
    ) {
      id
      calendarId
      entryId
      externalId
      externalLink
      quickEntryId
    }
  }
`;

// Used on the `/s` and `/p` pages to export QuickEntries
// (which we converted into the MagicEvent shape) to Google
export const useLegacyExportMagicEvents = () => {
  const { addExport, platformExports } = usePlatformExports();
  const { mutate, ...rest } = useMutation<
    ExportQuickEntriesMutation,
    QueryError,
    ExportQuickEntriesMutationVariables
  >({
    mutationFn: (variables) => gqlClient.request(query, variables),
  });

  const legacyExportMagicEvents = useCallback(
    (
      destination: Destination,
      quickEntries: MagicEventLike[],
      mutateOptions?: MutateOptions<
        ExportQuickEntriesMutation,
        QueryError,
        ExportQuickEntriesMutationVariables
      >
    ) => {
      const existingPlatformExport = platformExports.find(
        (item) => item.calendarId === destination.calendar.id
      );

      if (existingPlatformExport) {
        // Filter out entries that have already been added to the selected
        // calendar during this session so they're not added twice
        quickEntries = quickEntries.filter((entry) => {
          return !existingPlatformExport.entryExports.some(
            (item) => item.entry.id === entry.id
          );
        });
      }

      return mutate(
        {
          integrationId: destination.integration.id,
          quickEntryIds: quickEntries.map((item) => item.id),
          calendarId: destination.calendar.uri,
        },
        {
          ...mutateOptions,
          onSuccess: (result, ...reset) => {
            const entryLookup = quickEntries.reduce<
              Record<string, MagicEventLike>
            >((lookup, entry) => {
              return { ...lookup, [entry.id]: entry };
            }, {});

            addExport({
              platform: PlatformTypes.GoogleIntegration,
              integrationId: destination.integration.id,
              calendarId: destination.calendar.id,
              entryExports: result.exportQuickEntries.map((item) => {
                const entry =
                  (item.quickEntryId && entryLookup[item.quickEntryId]) ||
                  (item.entryId && entryLookup[item.entryId]) ||
                  null;

                if (!entry) {
                  throw new Error(`QuickEntry not found for export`);
                }

                return {
                  entry,
                  externalLink: getEventLink(destination.integration, item),
                };
              }),
            });

            mutateOptions?.onSuccess?.(result, ...reset);
          },
        }
      );
    },
    [mutate, addExport, platformExports]
  );
  return { legacyExportMagicEvents, ...rest };
};
