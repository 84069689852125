import type { PageExperienceConfig } from '../types';
import banner from './banner.png';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'her-board-meeting',
  isLive: true,
  primaryCta: 'subscribe',
  hidePastEntries: false,
  theme: {
    '--primary-color': '#60909E',
    '--secondary-color': '#EDF1FC',
  },
  header: {
    banner,
    logo,
  },
  subscribe: {
    google:
      'https://calendar.google.com/calendar/render?cid=webcal%3A%2F%2Fagendahero.com%2Ffeeds%2Fprivate-52d2aa0d-fd07-4bfd-a34a-3e557dc3170f',
    outlook:
      'https://outlook.live.com/owa/?path=/calendar/action/subscribe&url=https%3A%2F%2Fagendahero.com%2Ffeeds%2Fprivate-52d2aa0d-fd07-4bfd-a34a-3e557dc3170f',
    apple:
      'webcal://agendahero.com/feeds/private-52d2aa0d-fd07-4bfd-a34a-3e557dc3170f',
    other:
      'https://agendahero.com/feeds/private-52d2aa0d-fd07-4bfd-a34a-3e557dc3170f',
  },
};
