import { gql } from 'graphql-request';
import { useState } from 'react';
import { gqlClient, queryClient } from '@/lib';
import { MagicJobFragment } from '@/pages/QuickCreate/fragments/MagicJobFragment';
import { type ErrorCodes, MagicJobStatus } from '@/types/gql.generated';
import { ErrorWithCode, type QueryError } from '@/utils/errors';
import { createPollMagicJobQueryKey } from '@/utils/queryKeys';
import type {
  PollMagicJobStatusQuery,
  PollMagicJobStatusQueryVariables,
} from './usePollMagicJobStatus.generated';

const query = gql`
  ${MagicJobFragment}
  query PollMagicJobStatus($input: GetMagicJobInput!) {
    getMagicJob(input: $input) {
      ...MagicJob
    }
  }
`;

type State = {
  isLoading: boolean;
  error: Error | QueryError | null;
};

export const usePollMagicJobStatus = () => {
  const [state, setState] = useState<State>({
    error: null,
    isLoading: false,
  });

  const pollUntilJobComplete = async (
    magicJobId: string
  ): Promise<PollMagicJobStatusQuery['getMagicJob']> => {
    setState({ isLoading: true, error: null });

    return new Promise((resolve, reject) => {
      const poll = async () => {
        try {
          const { getMagicJob: job } = await queryClient.fetchQuery({
            queryKey: createPollMagicJobQueryKey(magicJobId),
            queryFn: () => {
              return gqlClient.request<
                PollMagicJobStatusQuery,
                PollMagicJobStatusQueryVariables
              >(query, { input: { magicJobId } });
            },
          });

          if (job.status === MagicJobStatus.Failed) {
            const message = job.errorMessage || job.errorCode || 'Job failed';
            throw new ErrorWithCode(message, job.errorCode as ErrorCodes);
          }

          if (job.status !== MagicJobStatus.Completed) {
            setTimeout(poll, 1000);
            return;
          }

          setState({ isLoading: false, error: null });
          resolve(job);
        } catch (err) {
          const error = err as State['error'];
          setState({ isLoading: false, error });
          reject(err);
        }
      };

      poll();
    });
  };

  const reset = () => {
    setState({ isLoading: false, error: null });
  };

  return { pollUntilJobComplete, reset, ...state };
};
