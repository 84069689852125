import type { BaseEmoji } from 'emoji-mart';
import { EmojiSelect } from '@/pages/Schedule/components';
import {
  type BoxProps,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@/ui';
import { EmojiButton } from './EmojiButton';

type Props = Omit<BoxProps, 'onClick' | 'onSelect'> & {
  value?: string | null;
  onSelect: (emoji: string | null) => void;
};

export const EmojiInput = ({ value, onSelect, ...props }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Popover
      isLazy
      isOpen={isOpen}
      offset={[0, 8]}
      placement="bottom-start"
      onClose={onClose}
    >
      <PopoverTrigger>
        <EmojiButton
          value={value}
          onClick={onOpen}
          onRemoveClick={(event) => {
            event.stopPropagation();
            onSelect(null);
          }}
          {...props}
        />
      </PopoverTrigger>
      <PopoverContent
        borderRadius="md"
        boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.1)"
        overflow="hidden"
      >
        <EmojiSelect
          style={{ width: '100%' }}
          onSelect={(emoji: BaseEmoji) => {
            onSelect(emoji.native);
            onClose();
          }}
        />
      </PopoverContent>
    </Popover>
  );
};
