import { defineStyle, defineStyleConfig } from '@/ui';

const text = defineStyle({
  textTransform: 'uppercase',
});

const xs = defineStyle({
  fontSize: '0.75em',
});

export const Badge = defineStyleConfig({
  sizes: { xs },
  variants: { text },
});
