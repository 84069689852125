import { Icon } from '@/ui';
import { Control } from '../Control';

type Props = {
  onClick: () => void;
};

export const AddContentButton = ({ onClick }: Props) => {
  return (
    <Control
      controlId="content"
      icon={<Icon icon="Plus" size="28px" strokeWidth="2px" />}
      label="Add content"
      onClick={onClick}
    />
  );
};
