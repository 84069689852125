import { persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

type State = {
  requested: boolean;
  hidden: boolean;
  setRequested: () => void;
  setHidden: () => void;
};

export const useRequestCustomColors = createWithEqualityFn<State>()(
  persist(
    (set) => ({
      requested: false,
      hidden: false,
      setRequested: () => set({ requested: true }),
      setHidden: () => set({ hidden: true }),
    }),
    {
      name: '_ah_request_custom_colors',
    }
  ),
  shallow
);
