import { Flex, Radio, RadioGroup, type RadioGroupProps, Text } from '@/ui';

type Option = {
  value: string;
  label: string;
  description?: string;
};

type Props<O extends Option, V extends O['value']> = Omit<
  RadioGroupProps,
  'value' | 'onChange' | 'children'
> & {
  options: O[];
  value: V;
  onChange: (value: V) => void;
};

export const OptionRadioGroup = <O extends Option, V extends O['value']>({
  value,
  options,
  onChange,
  ...props
}: Props<O, V>) => {
  return (
    <RadioGroup {...props} value={value} onChange={onChange}>
      <Flex direction="column" gap="3" justify="center">
        {options.map(({ value, label, description }) => (
          <Radio
            alignItems="flex-start"
            colorScheme="purple"
            key={value}
            value={value}
          >
            <Text fontWeight="medium" lineHeight="normal" mt="-2px">
              {label}
            </Text>
            {description && (
              <Text color="gray.800" fontSize="sm" lineHeight="1.3" mt="0.5">
                {description}
              </Text>
            )}
          </Radio>
        ))}
      </Flex>
    </RadioGroup>
  );
};
