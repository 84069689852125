import { Text, type TextProps } from '@/ui';

export const MenuHeading = (props: TextProps) => {
  return (
    <Text
      fontSize="11px"
      fontWeight="bold"
      letterSpacing="1.1px"
      mb="3"
      opacity="0.6"
      textTransform="uppercase"
      {...props}
    />
  );
};
