import type { PageExperienceConfig } from '../types';
import banner from './banner.png';
import logo from './logo.jpg';

export const config: PageExperienceConfig = {
  slug: 'bergen-county-audubon-society',
  primaryCta: 'subscribe',
  isLive: true,
  theme: {
    '--primary-color': '#2483d0',
    '--secondary-color': '#edf3f4',
  },
  header: {
    href: 'https://bergencountyaudubon.org',
    banner,
    logo,
  },
  subscribe: {
    google:
      'https://calendar.google.com/calendar/render?cid=webcal%3A%2F%2Fagendahero.com%2Ffeeds%2Fprivate-5088f854-b870-4ae7-bfc0-0daabf8f6e82',
    outlook:
      'https://outlook.live.com/owa/?path=/calendar/action/subscribe&url=https%3A%2F%2Fagendahero.com%2Ffeeds%2Fprivate-5088f854-b870-4ae7-bfc0-0daabf8f6e82',
    apple:
      'webcal://agendahero.com/feeds/private-5088f854-b870-4ae7-bfc0-0daabf8f6e82',
    other:
      'https://agendahero.com/feeds/private-5088f854-b870-4ae7-bfc0-0daabf8f6e82',
  },
};
