import { Form } from '@feathery/react';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useFeathery } from '@/pages/QuickCreate/hooks/useFeathery';
import {
  MotionFlex,
  type MotionFlexProps,
  Box,
  type BoxProps,
  Button,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
  forwardRef,
  Portal,
} from '@/ui';

const MotionContainer = forwardRef((props: MotionFlexProps, ref) => (
  <MotionFlex
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    ref={ref}
    transition={{ duration: 0.2 }}
    {...props}
  />
));

export const PublicFeedbackForm = (props: BoxProps) => {
  const [didSubmit, setSubmitted] = useState(false);
  const [isHidden, setHidden] = useState(false);
  const { ready } = useFeathery();

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (didSubmit) {
      timerId = setTimeout(() => setHidden(true), 3000);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [didSubmit]);

  if (!ready) {
    return null;
  }

  return (
    <Box {...props}>
      <AnimatePresence mode="popLayout">
        {isHidden ? null : didSubmit ? (
          <MotionContainer color="purple.600" fontWeight="bold" key="success">
            Thank you! 🎉
          </MotionContainer>
        ) : (
          <MotionContainer key="default">
            <Popover offset={[-100, 12]}>
              <PopoverTrigger>
                <Button
                  _hover={{ color: 'purple.800' }}
                  color="purple.600"
                  leftIcon={<Text lineHeight="1">📣</Text>}
                  variant="link"
                >
                  Share feedback
                </Button>
              </PopoverTrigger>
              <Portal>
                <PopoverContent>
                  <PopoverArrow />
                  <Form
                    formId="ZxuJCC"
                    onFormComplete={() => setSubmitted(true)}
                  />
                </PopoverContent>
              </Portal>
            </Popover>
          </MotionContainer>
        )}
      </AnimatePresence>
    </Box>
  );
};
