import merge from 'lodash.merge';
import { config as alighieriDanteConfig } from './alighieri-dante';
import { config as audubonConfig } from './audubon';
import { config as bissellbrothersEventsConfig } from './bissellbrothers-events';
import { config as bowNhConfig } from './bow-nh';
import { config as cougarNewsConfig } from './cougar-news';
import { config as doverDoulaConfig } from './dover-doula';
import { config as durhamFridayUpdatesConfig } from './durham-friday-updates';
import { config as durhamLibraryConfig } from './durham-library';
import { config as elixirBarConfig } from './elixir-bar';
import { config as firstGradeClassNewsletterConfig } from './first-grade-class-newsletter';
import { config as freyaVillageConfig } from './freya-village';
import { config as glassboxConfig } from './glassbox';
import { config as greatAmericanBeerConfig } from './great-american-beer';
import { config as herBoardMeeting } from './her-board';
import { config as herConference2024 } from './her-conference-2024';
import { config as hubspotSalesKickoffConfig } from './hubspot-sales-kickoff';
import { config as lanememorialConfig } from './lanememorial';
import { config as mascoMemoConfig } from './masco-memo';
import { config as massConferenceConfig } from './massachusetts-conference-for-women';
import { config as massConferencePreConfig } from './massachusetts-conference-for-women-pre';
import { config as medalliaSalesKickoffConfig } from './medallia-sales-kickoff';
import { config as menloEnotesConfig } from './menlo-enotes';
import { config as menoZipNotesConfig } from './menlo-zip-notes';
import { config as mercyNewsletterConfig } from './mercy-newsletter';
import { config as missStoneConfig } from './miss-stone';
import { config as myDoulaVillageConfig } from './my-doula-village';
import { config as newParentChecklistConfig } from './new-parent-checklist';
import { config as newmarketConfig } from './newmarket';
import { config as newmarketRecConfig } from './newmarket-rec';
import { config as ntfsConfig } from './ntfs';
import { config as ps9SchoolConfig } from './ps9-school';
import { config as saltwaterDoulasConfig } from './saltwater-doulas';
import { config as seacoastOutrightConfig } from './seacoast-outright';
import { config as showcaseTheWorldConfig } from './showcase-the-world';
import { config as shrekMusicalConfig } from './shrek-musical';
import { config as summerConcertSeriesConfig } from './summer-concert-series';
import { config as thirdGradeClassNewsletterConfig } from './third-grade-class-newsletter';
import { config as throwbackBreweryConfig } from './throwback-brewery';
import { config as torqSalesKickoffConfig } from './torq-sales-kickoff';
import { config as trinitySchoolConfig } from './trinity-school';
import { config as twistedFateConfig } from './twisted-fate-brewing';

export type PageExperienceConfig = {
  slug: string;
  /** CTA in the hero under the description */
  primaryCta?: 'subscribe' | 'send-to-google';
  /** CTA in the event cards. Defaults to `web-links */
  addToCalendar?: 'web-links' | 'email' | 'none';
  /** Is this POC live on agendahero.com instead of feedback.pr.agendahero.com? Defaults to false */
  isLive?: boolean;
  /** Hide events in the past. Defaults to true */
  hidePastEntries?: boolean;
  theme: {
    // color variables
    '--primary-color': string;
    '--primary-color-foreground'?: string;
    '--secondary-color': string;
    '--secondary-color-foreground'?: string;
    // usage variables
    '--background-color'?: string;
    '--text-color'?: string;
    '--link-color'?: string;
  };
  header?: {
    href?: string;
    banner: string;
    logo: string;
  };
  /** Web links if `primaryCta` is `subscribe` */
  subscribe?: {
    google: string;
    outlook: string;
    apple: string;
    other: string;
  };
};

// PoC's don't need to configure these properties but they won't
// be optional in the final config
export type MergedPageExperienceConfig = Required<
  Pick<PageExperienceConfig, 'addToCalendar'>
> &
  PageExperienceConfig;

export const DEFAULT_CONFIG: MergedPageExperienceConfig = {
  slug: '',
  isLive: false,
  hidePastEntries: true,
  addToCalendar: 'web-links',
  theme: {
    '--primary-color': '#2483d0',
    '--primary-color-foreground': '#ffffff',
    '--secondary-color': '#edf3f4',
    '--secondary-color-foreground': '#222222',
    '--background-color': 'var(--secondary-color)',
    '--text-color': 'var(--secondary-color-foreground)',
    '--link-color': 'var(--primary-color)',
  },
};

export const configs: Record<string, PageExperienceConfig> = {
  [audubonConfig.slug]: audubonConfig,
  [newmarketConfig.slug]: newmarketConfig,
  [lanememorialConfig.slug]: lanememorialConfig,
  [menloEnotesConfig.slug]: menloEnotesConfig,
  [menoZipNotesConfig.slug]: menoZipNotesConfig,
  [showcaseTheWorldConfig.slug]: showcaseTheWorldConfig,
  [throwbackBreweryConfig.slug]: throwbackBreweryConfig,
  [durhamLibraryConfig.slug]: durhamLibraryConfig,
  [mercyNewsletterConfig.slug]: mercyNewsletterConfig,
  [ps9SchoolConfig.slug]: ps9SchoolConfig,
  [trinitySchoolConfig.slug]: trinitySchoolConfig,
  [cougarNewsConfig.slug]: cougarNewsConfig,
  [missStoneConfig.slug]: missStoneConfig,
  [alighieriDanteConfig.slug]: alighieriDanteConfig,
  [firstGradeClassNewsletterConfig.slug]: firstGradeClassNewsletterConfig,
  [thirdGradeClassNewsletterConfig.slug]: thirdGradeClassNewsletterConfig,
  [massConferenceConfig.slug]: massConferenceConfig,
  [massConferencePreConfig.slug]: massConferencePreConfig,
  [summerConcertSeriesConfig.slug]: summerConcertSeriesConfig,
  [mascoMemoConfig.slug]: mascoMemoConfig,
  [seacoastOutrightConfig.slug]: seacoastOutrightConfig,
  [newParentChecklistConfig.slug]: newParentChecklistConfig,
  [durhamFridayUpdatesConfig.slug]: durhamFridayUpdatesConfig,
  [ntfsConfig.slug]: ntfsConfig,
  [medalliaSalesKickoffConfig.slug]: medalliaSalesKickoffConfig,
  [torqSalesKickoffConfig.slug]: torqSalesKickoffConfig,
  [hubspotSalesKickoffConfig.slug]: hubspotSalesKickoffConfig,
  [doverDoulaConfig.slug]: doverDoulaConfig,
  [glassboxConfig.slug]: glassboxConfig,
  [saltwaterDoulasConfig.slug]: saltwaterDoulasConfig,
  [newmarketRecConfig.slug]: newmarketRecConfig,
  [twistedFateConfig.slug]: twistedFateConfig,
  [shrekMusicalConfig.slug]: shrekMusicalConfig,
  [herBoardMeeting.slug]: herBoardMeeting,
  [herConference2024.slug]: herConference2024,
  [myDoulaVillageConfig.slug]: myDoulaVillageConfig,
  [greatAmericanBeerConfig.slug]: greatAmericanBeerConfig,
  [elixirBarConfig.slug]: elixirBarConfig,
  [bowNhConfig.slug]: bowNhConfig,
  [freyaVillageConfig.slug]: freyaVillageConfig,
  [bissellbrothersEventsConfig.slug]: bissellbrothersEventsConfig,
};

export const getConfig = (key = 'default'): MergedPageExperienceConfig =>
  key === 'default'
    ? DEFAULT_CONFIG
    : merge({}, DEFAULT_CONFIG, configs[key] ?? DEFAULT_CONFIG);
