import { Flex, Text } from '@/ui';
import { Confetti } from './Confetti';

export const ModalOnboardingHeader = () => {
  return (
    <Flex
      align="center"
      direction="column"
      flex="1"
      fontSize="lg"
      fontWeight="medium"
      justify="center"
      mb="4"
      mt="12"
      textAlign="center"
    >
      <Text fontSize="44px" letterSpacing="12px" lineHeight="1">
        🎉🎉🎉
      </Text>
      <Confetti mx="auto" />
      <Text fontSize="3xl" fontWeight="bold" lineHeight="normal" mb="3" mt="2">
        Welcome to your new events page!
      </Text>
      <Text maxW={{ base: '100%', md: 'none' }}>
        Get started by adding some content.
      </Text>
      <Text
        fontSize={{ base: 'md', md: 'lg' }}
        maxW={{ base: '90%', md: 'none' }}
      >
        💡 <strong>Tip:</strong> you can ask for multiple events at once
      </Text>
    </Flex>
  );
};
