import {
  RichTextEditor,
  type RichTextEditorProps,
} from '@/pages/Schedule/components';
import classes from './styles.module.scss';

type Props = Omit<RichTextEditorProps, 'ref'>;

export const DescriptionInput = (props: Props) => {
  return (
    <RichTextEditor
      className={classes.description}
      placeholder="Description"
      {...props}
    />
  );
};
