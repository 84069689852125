import { ModalHeader as ChakraModalHeader } from '@/ui';

export const ModalDefaultHeader = () => {
  return (
    <ChakraModalHeader
      borderBottomColor="customgray.gray"
      borderBottomWidth="1px"
      fontWeight="medium"
      lineHeight="1"
      pb="6"
      pt="7"
      px={{ base: 6, md: 8 }}
    >
      Add content
    </ChakraModalHeader>
  );
};
