import { gql } from 'graphql-request';

export const MagicEventFragment = gql`
  fragment MagicEvent on Event {
    id
    title
    emoji
    description
    locationWithPlace {
      name
      googlePlaceId
    }
    isAllDay
    rrule
    start {
      date
      dateTime
      timeZone
    }
    end {
      date
      dateTime
      timeZone
    }
  }
`;
