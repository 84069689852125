import type { PageExperienceConfig } from '../types';
import banner from './banner.jpg';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'masco-memo-nov-2024',
  hidePastEntries: false,
  theme: {
    '--primary-color': '#990001',
    '--secondary-color': '#fffdfd',
  },
  header: {
    href: 'https://masconomet.org',
    banner,
    logo,
  },
};
