import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import type { Feature, RequestAccessInput } from '@/types/gql.generated';
import type { QueryError } from '@/utils/errors';
import type {
  RequestAccessMutation,
  RequestAccessMutationVariables,
} from './useRequestAccess.generated';

const query = gql`
  mutation RequestAccess($input: RequestAccessInput!) {
    requestAccess(input: $input)
  }
`;

export const useRequestAccess = () => {
  const { mutate, isPending } = useMutation<
    RequestAccessMutation,
    QueryError,
    RequestAccessMutationVariables
  >({
    mutationFn: (variables) => {
      return gqlClient.request<
        RequestAccessMutation,
        RequestAccessMutationVariables
      >(query, variables);
    },
  });

  const requestAccess = (
    feature: Feature,
    options?: MutateOptions<
      RequestAccessMutation,
      QueryError,
      RequestAccessMutationVariables
    >
  ) => {
    const input: RequestAccessInput = {
      feature,
    };
    return mutate({ input }, options);
  };

  return { requestAccess, isPending };
};
