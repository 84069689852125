import { useBreakpointValue } from '@/ui';

type Value = string | number | Record<string, string | number> | undefined;

export const useResponsiveValue = (
  value: Value
): string | number | undefined => {
  const asObject =
    typeof value === 'string' || typeof value === 'number'
      ? { base: value }
      : value;

  return useBreakpointValue(asObject ?? {});
};
