import { usePageExperienceContext } from '@/features/experiences/contexts/PageExperienceContext';
import { useUpdateExperience } from '@/features/experiences/hooks/useUpdateExperience';
import { useAnalytics } from '@/hooks/useAnalytics';
import {
  AttributionOptions,
  BannerOptions,
  LogoOptions,
} from '@/types/gql.generated';
import { Flex } from '@/ui';
import { Control } from '../components/Control';
import { Menu } from '../components/Menu';
import { MenuHeading } from '../components/MenuHeading';
import { MenuSection } from '../components/MenuSection';
import { OptionToggle } from '../components/OptionToggle';
import { useControls } from '../hooks/useControls';

const TogglesMenu = () => {
  const { trackEvent } = useAnalytics();
  const { experience } = usePageExperienceContext();
  const {
    toggleEventCardEmojiEnabled,
    toggleEventCardDateEnabled,
    toggleEventCardDescriptionEnabled,
    toggleEventCardLocationEnabled,
    toggleBannerDisplay,
    toggleLogoDisplay,
    toggleDescriptionEnabled,
    toggleAttributionDisplay,
    toggleSubscribeLink,
  } = useUpdateExperience();

  return (
    <Menu>
      <MenuSection>
        <MenuHeading>Page</MenuHeading>
        <Flex direction="column" gap="3">
          <OptionToggle
            icon="Clock"
            isChecked={experience?.bannerDisplay !== BannerOptions.None}
            label="Header image"
            onChange={() => {
              trackEvent('experience:author change toggles', {
                setting: 'page-banner',
              });
              toggleBannerDisplay();
            }}
          />
          <OptionToggle
            icon="Hexagon"
            isChecked={experience?.logoDisplay !== LogoOptions.None}
            label="Logo"
            onChange={() => {
              trackEvent('experience:author change toggles', {
                setting: 'page-logo',
              });
              toggleLogoDisplay();
            }}
          />
          <OptionToggle
            icon="User"
            label="Author"
            isChecked={
              experience?.attributionDisplay ===
              AttributionOptions.AvatarFullName
            }
            onChange={() => {
              trackEvent('experience:author change toggles', {
                setting: 'page-attribution',
              });
              toggleAttributionDisplay();
            }}
          />
          <OptionToggle
            icon="LetterText"
            isChecked={experience?.descriptionEnabled}
            label="Description"
            onChange={() => {
              trackEvent('experience:author change toggles', {
                setting: 'page-description',
              });
              toggleDescriptionEnabled();
            }}
          />
          <OptionToggle
            icon="CalendarCheck"
            isChecked={experience?.subscribeEnabled}
            label="Subscribe link"
            onChange={() => {
              trackEvent('experience:author change toggles', {
                setting: 'page-subscribe',
              });
              toggleSubscribeLink();
            }}
          />
        </Flex>
        <MenuHeading mt="8">Events</MenuHeading>
        <Flex direction="column" gap="3">
          <OptionToggle
            icon="Smile"
            isChecked={experience?.eventCardEmojiEnabled}
            label="Emoji"
            onChange={() => {
              trackEvent('experience:author change toggles', {
                setting: 'event-emoji',
              });
              toggleEventCardEmojiEnabled();
            }}
          />
          <OptionToggle
            icon="Calendar"
            isChecked={experience?.eventCardDateEnabled}
            label="Date and Time"
            onChange={() => {
              trackEvent('experience:author change toggles', {
                setting: 'event-timing',
              });
              toggleEventCardDateEnabled();
            }}
          />
          <OptionToggle
            icon="LetterText"
            isChecked={experience?.eventCardDescriptionEnabled}
            label="Description"
            onChange={() => {
              trackEvent('experience:author change toggles', {
                setting: 'event-description',
              });
              toggleEventCardDescriptionEnabled();
            }}
          />
          <OptionToggle
            icon="MapPin"
            isChecked={experience?.eventCardLocationEnabled}
            label="Location"
            onChange={() => {
              trackEvent('experience:author change toggles', {
                setting: 'event-location',
              });
              toggleEventCardLocationEnabled();
            }}
          />
        </Flex>
      </MenuSection>
    </Menu>
  );
};

export const TogglesControl = () => {
  const { register } = useControls();

  return (
    <Control
      icon="ToggleRight"
      label="Toggles"
      {...register('toggles')}
      menu={<TogglesMenu />}
    />
  );
};
