import { usePageExperienceContext } from '@/features/experiences/contexts/PageExperienceContext';
import { useSetAddToCalendarMode } from '@/features/experiences/hooks/useSetAddToCalendarMode';
import { getExperienceAddToCalendarMode } from '@/features/experiences/lib/interactivity/getExperienceAddToCalendarMode';
import { useAnalytics } from '@/hooks/useAnalytics';
import { AddToCalendarConfigMode } from '@/types/gql.generated';
import { Control } from '../components/Control';
import { Menu } from '../components/Menu';
import { MenuHeading } from '../components/MenuHeading';
import { MenuSection } from '../components/MenuSection';
import { OptionRadioGroup } from '../components/OptionRadioGroup';
import { useControls } from '../hooks/useControls';

const options = [
  {
    value: AddToCalendarConfigMode.EmailInvite,
    label: 'Calendar invite',
    description: 'Best if events are likely to change.',
  },
  {
    value: AddToCalendarConfigMode.WebLink,
    label: 'Copy to calendar',
    description: "Recommended only if event details aren't likely to change.",
  },
  {
    value: AddToCalendarConfigMode.None,
    label: 'None',
  },
];

const InteractivityMenu = () => {
  const { trackEvent } = useAnalytics();
  const { experience } = usePageExperienceContext();
  const { setAddToCalendarAppMode } = useSetAddToCalendarMode();
  const addToCalendarMode = getExperienceAddToCalendarMode(experience);

  return (
    <Menu>
      <MenuSection>
        <MenuHeading>Interactivity</MenuHeading>

        <OptionRadioGroup
          options={options}
          value={addToCalendarMode}
          onChange={(value) => {
            trackEvent(
              'experience:author change interaction-add-event-settings',
              {
                setting: 'mode',
              }
            );
            setAddToCalendarAppMode(value);
          }}
        />
      </MenuSection>
    </Menu>
  );
};

export const InteractivityControl = () => {
  const { register } = useControls();

  return (
    <Control
      icon="MousePointerClick"
      label="Interactivity"
      menu={<InteractivityMenu />}
      {...register('interactivity')}
    />
  );
};
