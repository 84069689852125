import type { ChakraProps } from '@/ui';

export const layerStyles: Record<string, ChakraProps> = {
  magicBackground: {
    bgImage: 'linear-gradient(#dcd3ef, rgba(245, 225, 239, 0.5))',
    bgAttachment: 'fixed',
  },
  magicBackgroundSecondary: {
    bgImage: 'linear-gradient(180deg, #D1D6F4 0%, #F9EAF3 100%)',
    bgAttachment: 'fixed',
  },
  magicBackgroundTertiary: {
    bgImage: 'linear-gradient(267deg, #D2E4FF 8.07%, #FFE0F5 94.94%)',
    bgAttachment: 'fixed',
  },
};
