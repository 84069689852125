import { useAddContentModalStore } from '../../store';
import { ModalDefaultHeader } from './ModalDefaultHeader';
import { ModalOnboardingHeader } from './ModalOnboardingHeader';

type Props = {
  pollJobSuccess: boolean;
};

export const ModalHeader = ({ pollJobSuccess }: Props) => {
  const { config } = useAddContentModalStore();

  if (config.launchState === 'onboarding' && !pollJobSuccess) {
    return <ModalOnboardingHeader />;
  }

  return <ModalDefaultHeader />;
};
