import { EntriesJobType, MagicJobType } from '@/types/gql.generated';
import type { ErrorWithCode, QueryError } from '@/utils/errors';

export type PromptType = EntriesJobType | MagicJobType;

export type ErrorInput = QueryError | ErrorWithCode;

export const isFileType = (type?: PromptType): boolean => {
  return type === MagicJobType.File || type === EntriesJobType.File;
};
