import type { PageExperienceConfig } from '../types';
import banner from './banner.png';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'ps9-sarah-anderson-school',
  theme: {
    '--primary-color': '#003193',
    '--secondary-color': '#e6e2ff',
  },
  header: {
    href: 'https://www.ps9.org',
    banner,
    logo,
  },
};
