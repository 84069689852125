import { CheckIcon } from '@chakra-ui/icons';
import { toHslString } from '@/features/experiences/lib/colors';
import type { HSL } from '@/features/experiences/types';
import { Flex, type FlexProps, type UseRadioProps, useRadio } from '@/ui';

type Props = UseRadioProps & {
  color: HSL;
  hasBorder?: boolean;
  containerProps?: FlexProps;
  onChange: (color: HSL) => void;
};

export const RadioColor = ({
  color,
  hasBorder,
  containerProps,
  ...props
}: Props): JSX.Element => {
  const { getInputProps, getRadioProps } = useRadio(props);

  return (
    <Flex align="center" as="label" cursor="pointer" {...containerProps}>
      <input {...getInputProps()} />

      <Flex
        {...getRadioProps()}
        align="center"
        bgColor={toHslString(color)}
        borderColor="gray.400"
        borderRadius="full"
        borderWidth={hasBorder ? '1px' : 0}
        boxSize="24px"
        justify="center"
        transition="all 0.15s ease"
      >
        <CheckIcon
          boxSize="12px"
          color={hasBorder ? 'black' : 'white'}
          display={props.isChecked ? 'block' : 'none'}
        />
      </Flex>
    </Flex>
  );
};
