import {
  Flex,
  Slider,
  SliderFilledTrack,
  type SliderProps,
  SliderThumb,
  SliderTrack,
  Text,
} from '@/ui';

type Props = SliderProps & {
  label: string;
  trackBgColor?: string;
  filledTrackBgColor?: string;
};

export const OptionSlider = ({
  label,
  trackBgColor,
  filledTrackBgColor,
  ...props
}: Props) => {
  return (
    <Flex flexDir="column" gap="1.5">
      <Text fontWeight="bold">{label}</Text>

      <Slider aria-label={label} colorScheme="purple" {...props}>
        <SliderTrack bg={trackBgColor} borderRadius="lg" h="6px">
          <SliderFilledTrack bg={filledTrackBgColor} />
        </SliderTrack>
        <SliderThumb borderColor="black" boxSize="18px" />
      </Slider>
    </Flex>
  );
};
