import type { PageExperienceConfig } from '../types';
import banner from './banner.png';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'massachusetts-conference-for-women',
  theme: {
    '--primary-color': '#1F5087',
    '--secondary-color': '#f0f0f0',
  },
  header: {
    href: 'https://www.maconferenceforwomen.org',
    banner,
    logo,
  },
};
