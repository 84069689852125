import { useParams } from 'react-router-dom';
import { QuickCreateContextProvider } from '../../contexts/QuickCreateContext';
import { useGetQuickEntriesPage } from '../../hooks/useGetQuickEntriesPage';
import { useIsSharePage } from '../../hooks/useIsSharePage';
import { JobDetail } from './JobDetail';

export const JobDetailLoader = () => {
  const { slug: slugOrId } = useParams<{ slug: string }>();
  const { isPlatformSharePage } = useIsSharePage();

  const { job, page, isLoading, error, reset } = useGetQuickEntriesPage(
    isPlatformSharePage ? 'platform' : 'magic',
    slugOrId
  );

  return (
    <QuickCreateContextProvider job={job} page={page} readOnly onReset={reset}>
      <JobDetail error={error} isLoading={isLoading} />
    </QuickCreateContextProvider>
  );
};
