import { MotionFlex } from '@/ui';
import {
  ModalContent as ChakraModalContent,
  type ModalContentProps,
} from '@/ui';
import { useAddContentModalStore } from '../store';

type Props = ModalContentProps & {
  pollJobSuccess: boolean;
};

export const ModalContent = ({ pollJobSuccess, ...props }: Props) => {
  const { config } = useAddContentModalStore();

  // The modal must be a fixed width and height in order to animate
  // the expansion state from loading to success. Using auto will
  // not work.
  const initialDesktopHeight =
    config.launchState === 'onboarding' ? '660px' : '524px';

  const initialDesktopWidth = '750px';

  return (
    <ChakraModalContent
      as={MotionFlex}
      borderRadius={{ base: 0, md: '2xl' }}
      m="0"
      opacity="1"
      overflowY={pollJobSuccess ? 'hidden' : 'initial'}
      transitionDuration="0.5s"
      transitionProperty="max-width, height"
      transitionTimingFunction="ease-in-out"
      maxH={{
        base: 'var(--app-height)',
        md: pollJobSuccess ? 'calc(100vh - var(--chakra-space-20))' : 'none',
      }}
      maxW={{
        md: pollJobSuccess
          ? 'calc(100vw - var(--chakra-space-20))'
          : initialDesktopWidth,
        xl: pollJobSuccess
          ? 'calc(1400px - var(--chakra-space-20))'
          : initialDesktopWidth,
      }}
      minH={{
        base: 'var(--app-height)',
        md: 'auto',
      }}
      minW={{
        base: '100vw',
        md: 'auto',
      }}
      sx={{
        '--add-content-modal-bg-light': 'var(--chakra-colors-purple-50)',
        '--add-content-modal-bg-dark': 'var(--chakra-colors-purple-100)',
        '--add-content-modal-border-color': '#D8D5E1',
        // the modal in the success state is always 900px tall unless
        // the screen is < 900px tall, at which point it's 100%
        // of the screen height with gutters. but only on desktop.
        // `min-width: 768px` is the `md` breakpoint (can't use css
        // vars in media queries)
        '@media (min-width: 768px) and (min-height: 900px)': {
          h: pollJobSuccess ? '900px' : initialDesktopHeight,
        },
        '@media (min-width: 768px) and (max-height: 900px)': {
          h: pollJobSuccess
            ? 'calc(var(--app-height) - var(--chakra-space-20))'
            : initialDesktopHeight,
        },
      }}
      {...props}
    />
  );
};
