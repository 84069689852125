import { usePageExperienceContext } from '@/features/experiences/contexts/PageExperienceContext';
import { useUpdateExperience } from '@/features/experiences/hooks/useUpdateExperience';
import { toHslString } from '@/features/experiences/lib/colors';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { ColorTypes } from '@/types/gql.generated';
import { Control } from '../../components/Control';
import { Menu } from '../../components/Menu';
import { MenuSection } from '../../components/MenuSection';
import { OptionSlider } from '../../components/OptionSlider';
import { useControls } from '../../hooks/useControls';
import { ColorInput } from './ColorInput';
import { RequestCustomColors } from './RequestCustomColors';
import { useRequestCustomColors } from './useRequestCustomColors';

const StylesMenu = () => {
  const { trackEvent } = useAnalytics();
  const { experience } = usePageExperienceContext();
  const { hidden } = useRequestCustomColors();
  const { value: experiencesCustomColorEnabled } = useFeatureFlag(
    'experiencesCustomColorEnabled'
  );
  const { setBoldness, setSparkle, setHsl, setColorType } =
    useUpdateExperience();

  return (
    <Menu>
      <MenuSection gap="5">
        <OptionSlider
          label="Boldness"
          value={experience?.bold}
          onChange={(value) => setBoldness(value)}
          onChangeEnd={(value) => {
            trackEvent('experience:author change styles', {
              setting: 'bold',
            });
            setBoldness(value, true);
          }}
        />
        <OptionSlider
          label="✨ Sparkle ✨"
          value={experience?.sparkle}
          onChange={(value) => setSparkle(value)}
          onChangeEnd={(value) => {
            trackEvent('experience:author change styles', {
              setting: 'sparkle',
            });
            setSparkle(value, true);
          }}
        />
        <ColorInput
          value={toHslString(experience)}
          onSelectCustomColor={() => setColorType(ColorTypes.Custom)}
          onSelectColor={(color) => {
            trackEvent('experience:author change styles', {
              setting: 'color',
            });
            setHsl(color);
          }}
        />
      </MenuSection>

      {!experiencesCustomColorEnabled && !hidden && (
        <MenuSection borderTopRadius="none" borderTopWidth="1px" pb="5" pt="4">
          <RequestCustomColors />
        </MenuSection>
      )}
    </Menu>
  );
};

export const StylesControl = () => {
  const { register } = useControls();

  return (
    <Control
      icon="Palette"
      label="Styles"
      {...register('styles')}
      menu={<StylesMenu />}
    />
  );
};
