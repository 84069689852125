import {
  PencilIcon,
  type LucideIcon,
  TagIcon,
  UserRound,
  PaletteIcon,
  MessageSquareTextIcon,
  Trash2Icon,
  CalendarDays,
  MapPin,
  CopyIcon,
  Mail,
  CalendarPlus,
  MoreHorizontal,
  CalendarCheck,
  XIcon,
  Plus,
  ArrowLeft,
  ArrowUpRight,
  Check,
  Crown,
  Heart,
  RefreshCw,
  Search,
  WandSparkles,
  Clock,
  Link,
  ArrowRight,
  Scissors,
  ChevronLeft,
  ChevronRight,
  ChevronDown,
  Eye,
  EyeOff,
  Menu,
  HelpCircle,
  LogOut,
  FileSearch,
  Settings,
  Smile,
  LayoutPanelTop,
  CreditCard,
  ToggleRight,
  MousePointerClick,
  Calendar,
  Hexagon,
  LetterText,
  Upload,
  Repeat,
  PartyPopper,
  Forward,
} from 'lucide-react';
import type { IconType as ReactIconsType } from 'react-icons';

export type IconType = LucideIcon | ReactIconsType;

export const Icons = {
  ArrowLeft,
  ArrowRight,
  ArrowUpRight,
  Calendar: CalendarDays,
  CalendarCheck: CalendarCheck,
  CalendarPlus: CalendarPlus,
  CalendarBlank: Calendar,
  Checkmark: Check,
  ChevronLeft,
  ChevronRight,
  ChevronDown,
  Clock,
  Copy: CopyIcon,
  CreditCard,
  Crown,
  Eye,
  EyeOff,
  FileSearch,
  Forward,
  Heart,
  HelpCircle,
  Hexagon,
  Layout: LayoutPanelTop,
  LetterText,
  Link,
  Logout: LogOut,
  Mail,
  MapPin,
  Menu,
  MoreHorizontal,
  MousePointerClick,
  Pencil: PencilIcon,
  Palette: PaletteIcon,
  PartyPopper,
  Plus,
  Refresh: RefreshCw,
  Repeat,
  Scissors,
  Search,
  Settings,
  Smile,
  Tag: TagIcon,
  TextMessage: MessageSquareTextIcon,
  ToggleRight,
  Trash: Trash2Icon,
  Upload,
  User: UserRound,
  WandSparkles,
  X: XIcon,
} as const satisfies Record<string, IconType>;
