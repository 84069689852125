import type { ComponentProps } from 'react';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import type { Control } from '../components/Control';

type ControlProps = Pick<
  ComponentProps<typeof Control>,
  'controlId' | 'isOpen' | 'onClick'
>;

type State = {
  openControl: string | null;
  toggle: (controlId: string) => void;
  close: () => void;
  register: (controlId: string) => ControlProps;
};

export const useControls = createWithEqualityFn<State>()(
  (set, get) => ({
    openControl: null,
    toggle: (menu) =>
      set({ openControl: get().openControl === menu ? null : menu }),
    close: () => set({ openControl: null }),
    register: (id: string) => ({
      controlId: id,
      isOpen: get().openControl === id,
      onClick: () => get().toggle(id),
    }),
  }),
  shallow
);
