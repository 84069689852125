import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient, queryClient } from '@/lib';
import type { QueryError } from '@/utils/errors';
import {
  createLibraryQueryKey,
  createSchedulesQueryKey,
} from '@/utils/queryKeys';
import { useLibraryCache } from '../useLibraryCache';
import type {
  DeleteScheduleMutation,
  DeleteScheduleMutationVariables,
} from './useDeleteSchedule.generated';

const query = gql`
  mutation DeleteSchedule($scheduleId: ID!) {
    deleteSchedule(scheduleId: $scheduleId)
  }
`;

export const useDeleteSchedule = () => {
  const { removeLibraryItem } = useLibraryCache();

  const { mutate, ...rest } = useMutation<
    DeleteScheduleMutation,
    QueryError,
    DeleteScheduleMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        DeleteScheduleMutation,
        DeleteScheduleMutationVariables
      >(query, variables),
  });

  const deleteSchedule = (
    scheduleId: string,
    options?: MutateOptions<
      DeleteScheduleMutation,
      QueryError,
      DeleteScheduleMutationVariables
    >
  ) => {
    mutate(
      { scheduleId },
      {
        onSuccess: (...args) => {
          removeLibraryItem(scheduleId);

          // Timeout ensures state is updated before navigating
          setTimeout(() => options?.onSuccess?.(...args));

          // clear everything in case this schedule is a source on another schedule
          queryClient.removeQueries({ queryKey: createSchedulesQueryKey() });
          queryClient.removeQueries({ queryKey: createLibraryQueryKey() });
        },
      }
    );
  };

  return {
    ...rest,
    deleteSchedule,
  };
};
