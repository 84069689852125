import { useEffect, useState } from 'react';
import { useQuickCreateContext } from '@/pages/QuickCreate/contexts';
import { MagicJobSource } from '@/types/gql.generated';

const scrollToElementWhenIdle = (el: HTMLElement | undefined | null): void => {
  if (!el) {
    return;
  }

  // Safari doesn't support requestIdleCallback
  const onIdle = window.requestIdleCallback || ((fn) => setTimeout(fn, 50));

  onIdle(() => {
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  });
};

export const useScrollToResults = () => {
  const [resultsContainer, setResultsContainer] =
    useState<HTMLDivElement | null>(null);

  const { job, state } = useQuickCreateContext();
  const isAppSource = job?.source === MagicJobSource.App;

  // Scroll to the results on mount for app source jobs
  useEffect(() => {
    const timerId = setTimeout(() => {
      if (isAppSource && resultsContainer) {
        // scrolling to the preview element doesn't work when other animations
        // are active (in this case it's the collapse of the generate button),
        // so the animation is scheduled once the browser has an idle frame.
        scrollToElementWhenIdle(resultsContainer);
      }
    }, 200);

    return () => clearTimeout(timerId);
  }, [isAppSource, resultsContainer]);

  // Scroll to results when the state changes to 'loading'.
  // This is relevant on mobile when the loading state
  // is below the prompt box
  useEffect(() => {
    if (state === 'loading' && resultsContainer) {
      scrollToElementWhenIdle(resultsContainer);
    }
  }, [state, resultsContainer]);

  return { setResultsContainer };
};
