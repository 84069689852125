import type { PageExperienceConfig } from '../types';
import banner from './banner.png';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'shrek-spring-2025',
  primaryCta: 'subscribe',
  addToCalendar: 'email',
  hidePastEntries: false,
  theme: {
    '--primary-color': '#253E05',
    '--secondary-color': '#f5f5f5',
  },
  header: {
    banner,
    logo,
  },
  subscribe: {
    google:
      'https://calendar.google.com/calendar/render?cid=webcal%3A%2F%2Fagendahero.com%2Ffeeds%2Fprivate-5ae9d3fc-1a4f-458e-9540-5158ba71a918',
    outlook:
      'https://outlook.live.com/owa/?path=/calendar/action/subscribe&url=https%3A%2F%2Fagendahero.com%2Ffeeds%2Fprivate-5ae9d3fc-1a4f-458e-9540-5158ba71a918',
    apple:
      'webcal://agendahero.com/feeds/private-5ae9d3fc-1a4f-458e-9540-5158ba71a918',
    other:
      'https://agendahero.com/feeds/private-5ae9d3fc-1a4f-458e-9540-5158ba71a918',
  },
};
