import { Box, Flex, Text } from '@/ui';

type Props = {
  label?: string;
};

const Bar = () => (
  <Box
    _groupHover={{ bg: 'rgba(113, 106, 221, 0.20)' }}
    bg="rgba(150, 150, 150, 0.20)"
    borderRadius="2px"
    height="6px"
    sx={{ '.active &': { bg: 'rgba(113, 106, 221, 0.20)' } }}
    w="100%"
  />
);

export const LayoutIcon = ({ label }: Props) => {
  return (
    <Flex flexDir="column" gap="2px" w="70%">
      {label && (
        <Text
          color="gray.700"
          fontSize="10px"
          fontWeight="bold"
          mb="1px"
          textAlign="left"
        >
          {label}
        </Text>
      )}
      <Bar />
      <Bar />
      <Bar />
    </Flex>
  );
};
