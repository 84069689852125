import { useKeyPressEvent } from 'react-use';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { Divider } from '@/ui';
import { AddContentButton } from './components/AddContentButton';
import { Container } from './components/Container';
import { EventsControl } from './controls/EventsControl';
import { InteractivityControl } from './controls/InteractivityControl';
import { LayoutControl } from './controls/LayoutControl';
import { StylesControl } from './controls/StylesControl';
import { TogglesControl } from './controls/TogglesControl';
import { useControls } from './hooks/useControls';
import { useOutsideClickHandler } from './hooks/useOutsideClickHandler';

type Props = {
  onAddContentClick: () => void;
};

export const Controls = ({ onAddContentClick }: Props) => {
  const { close } = useControls();
  const containerRef = useOutsideClickHandler(close);
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { value: experiencesLayoutTabEnabled } = useFeatureFlag(
    'experiencesLayoutTabEnabled'
  );
  useKeyPressEvent('Escape', close);

  const handleAddContentClick = () => {
    close();
    onAddContentClick();
  };

  return (
    <Container
      ref={containerRef}
      sx={{
        '--control-box-size': '48px',
        '--control-border-radius': 'var(--chakra-radii-xl)',
      }}
    >
      <AddContentButton onClick={handleAddContentClick} />
      <Divider
        borderColor="gray.500"
        h={{ base: 'var(--control-box-size)', md: 'auto' }}
        orientation={isMobileBreakpoint ? 'vertical' : 'horizontal'}
      />
      <StylesControl />
      {experiencesLayoutTabEnabled && <LayoutControl />}
      <TogglesControl />
      <EventsControl />
      <InteractivityControl />
    </Container>
  );
};
