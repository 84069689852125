// TODO move the location of this file
import { COLOR_DEFINITIONS } from '@/features/experiences/components/PageExperience/layouts/ListVerticalLayout/hooks/useListLayoutTheme';
import { usePageExperienceContext } from '@/features/experiences/contexts/PageExperienceContext';
import { isWhiteColor, toHslString } from '@/features/experiences/lib/colors';
import type { HSL } from '@/features/experiences/types';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { ColorTypes } from '@/types/gql.generated';
import {
  Flex,
  forwardRef,
  Text,
  useRadioGroup,
  type UseRadioGroupProps,
} from '@/ui';
import { RadioColor } from './RadioColor';

type Props = Omit<UseRadioGroupProps, 'value'> & {
  value?: string;
  onSelectColor: (color: HSL) => void;
  onSelectCustomColor: () => void;
};

export const ColorInput = forwardRef(
  (
    { value, onSelectColor, onSelectCustomColor, ...props }: Props,
    ref
  ): JSX.Element => {
    const { experience } = usePageExperienceContext();
    const { value: experiencesCustomColorEnabled } = useFeatureFlag(
      'experiencesCustomColorEnabled'
    );
    const { getRootProps, getRadioProps } = useRadioGroup({
      ...props,
      value,
    });

    const customColor = {
      hue: experience?.customHue ?? 0,
      saturation: experience?.customSaturation ?? 0,
      lightness: experience?.customLightness ?? 0,
    };

    return (
      <Flex flexDir="column" gap="1.5">
        <Text fontWeight="bold">Color</Text>

        <Flex gap="1.5" justify="space-between" {...getRootProps()} ref={ref}>
          {experiencesCustomColorEnabled && (
            <Flex
              borderRightColor="gray.200"
              borderRightWidth="1px"
              h="100%"
              mr="1"
              pr="2.5"
            >
              <RadioColor
                color={customColor}
                {...getRadioProps({ value: customColor })}
                isChecked={experience?.colorType === ColorTypes.Custom}
                onChange={onSelectCustomColor}
              />
            </Flex>
          )}
          {COLOR_DEFINITIONS.map((color) => {
            const hsl = toHslString(color);

            return (
              <RadioColor
                color={color}
                key={hsl}
                {...getRadioProps({ value: hsl })}
                hasBorder={isWhiteColor(color)}
                isChecked={
                  experience?.colorType === ColorTypes.Standard && value === hsl
                }
                onChange={() => onSelectColor?.(color)}
              />
            );
          })}
        </Flex>
      </Flex>
    );
  }
);
