import { AppLoader } from '@/components/AppLoader';
import { QuickCreateErrorMessage } from '@/components/QuickCreateErrorMessage';
import { usePageTitle } from '@/hooks/usePageTitle';
import { useTrackPageView } from '@/hooks/useTrackPageView';
import { useQuickCreateContext } from '@/pages/QuickCreate/contexts';
import { isGQLErrorOfType, type QueryError } from '@/utils/errors';
import { AuthModal } from '../../components/AuthModal';
import { CenteredContent } from '../../components/CenteredContent';
import { Layout } from '../../components/Layout';
import { NotFound } from '../../components/NotFound';
import { Details } from './components/Details';

type Props = {
  isLoading: boolean;
  error: QueryError | null;
};

export const JobDetail = ({ isLoading, error }: Props) => {
  const { job, page } = useQuickCreateContext();
  usePageTitle(page?.title);
  useTrackPageView('magic:detail');

  return (
    <>
      <AuthModal />

      <Layout pt="4">
        <CenteredContent>
          {isLoading ? (
            <AppLoader />
          ) : isGQLErrorOfType(error, 'QuickEntriesPageNotFound') ? (
            <NotFound />
          ) : error ? (
            <QuickCreateErrorMessage error={error} />
          ) : job ? (
            <Details />
          ) : null}
        </CenteredContent>
      </Layout>
    </>
  );
};
