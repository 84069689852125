import { createWithEqualityFn } from 'zustand/traditional';
import type { PlatformTypes } from '@/types/gql.generated';
import type { DecoratedMagicEvent } from '../types';

type State = {
  isOpen: boolean;
  platform: PlatformTypes | null;
  relatedEntry: DecoratedMagicEvent | null;
};

type Actions = {
  trigger: (entry: DecoratedMagicEvent | null, platform: PlatformTypes) => void;
  dismiss: () => void;
};

type Store = State & Actions;

const initialState: State = {
  isOpen: false,
  platform: null,
  relatedEntry: null,
};

export const usePinChangeTip = createWithEqualityFn<Store>((set) => ({
  ...initialState,
  dismiss: () => set({ isOpen: false, platform: null, relatedEntry: null }),
  trigger: (relatedEntry, platform) =>
    set({ isOpen: true, platform, relatedEntry }),
}));
