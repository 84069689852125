import type { ComponentProps } from 'react';
import { Box } from '@/ui';
import { CenteredContent } from '../CenteredContent';
import heroDesktop from './hero-desktop.png';
import heroMobile from './hero-mobile.png';

type Props = ComponentProps<typeof CenteredContent>;

export const Header = ({ children, ...props }: Props) => {
  return (
    <>
      <Box
        bgImage={heroMobile}
        bgPos="center"
        bgRepeat="no-repeat"
        bgSize="100%"
        display={{ base: 'block', md: 'none' }}
        h="0"
        pt="calc(350 / 786 * 100%)"
      />
      <CenteredContent align="center" flex="0" textAlign="center" {...props}>
        <Box
          aspectRatio="1704 / 571"
          bgImage={heroDesktop}
          bgPos="center"
          bgRepeat="no-repeat"
          bgSize="100%"
          display={{ base: 'none', md: 'block' }}
          w="100%"
        />

        {children}
      </CenteredContent>
    </>
  );
};
