import { useMemo } from 'react';
import { Icon, type IconProps } from '@/ui';
import { useResponsiveValue } from '@/utils/responsive';
import SvgLogoBlack from './logo-black.svg?react';
import SvgLogoPink from './logo.svg?react';

const WIDTH = 31;
const HEIGHT = 33;
const aspectRatio = WIDTH / HEIGHT;

type Props = Omit<IconProps, 'w' | 'width' | 'h' | 'height'> & {
  variant?: 'black' | 'pink';
  width?: number | Record<string, number>;
  height?: number | Record<string, number>;
};

export const Logo = ({ variant = 'pink', width, height, ...props }: Props) => {
  const responsiveWidth = useResponsiveValue(width);
  const responsiveHeight = useResponsiveValue(height);

  const dimensions = useMemo(() => {
    if (responsiveWidth) {
      return {
        width: `${responsiveWidth}px`,
        height: `calc(${responsiveWidth}px * ${1 / aspectRatio})`,
      };
    }
    if (responsiveHeight) {
      return {
        width: `calc(${responsiveHeight}px * ${aspectRatio})`,
        height: `${responsiveHeight}px`,
      };
    }
    return { width: `${WIDTH}px`, height: `${HEIGHT}px` };
  }, [responsiveWidth, responsiveHeight]);

  return (
    <Icon
      as={variant === 'pink' ? SvgLogoPink : SvgLogoBlack}
      {...props}
      {...dimensions}
    />
  );
};
