import { Flex, type FlexProps } from '@/ui';
import { MenuCloseButton } from './MenuCloseButton';

export const Menu = ({ children, ...props }: FlexProps) => {
  return (
    <Flex
      bg="white"
      borderRadius="2xl"
      direction="column"
      minH="200px"
      minW="320px"
      overflow="hidden"
      pos="relative"
      shadow="0px 0px 14px 0px rgba(0, 0, 0, 0.15)"
      sx={{
        '--menu-padding': 'var(--chakra-space-6)',
      }}
      {...props}
    >
      <MenuCloseButton />
      {children}
    </Flex>
  );
};
