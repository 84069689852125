import { gql } from 'graphql-request';
import { MagicEventFragment } from './MagicEventFragment';

export const MagicJobFragment = gql`
  ${MagicEventFragment}
  fragment MagicJob on MagicJob {
    id
    type
    status
    source
    sourceText
    errorCode
    errorMessage
    createdAt
    prompt
    file {
      id
      filename
      mimetype
      url
    }
    events {
      ...MagicEvent
    }
  }
`;
