import without from 'lodash/without';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BsLightningChargeFill } from 'react-icons/bs';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import type { Category } from '@/pages/Schedule/components/Category';
import {
  useScheduleContext,
  useScheduleFilters,
} from '@/pages/Schedule/contexts';
import colorWheel from '@/pages/Schedule/icons/color-wheel.png';
import labelIcon from '@/pages/Schedule/icons/label.png';
import whoIcon from '@/pages/Schedule/icons/who.png';
import type { ScheduleLocationState } from '@/pages/Schedule/types';
import type { Label } from '@/types/gql.generated';
import {
  Flex,
  Button,
  DarkMode,
  Popover,
  PopoverBody,
  PopoverContent,
  Tabs,
  TabPanels,
  useDisclosure,
  VStack,
  PopoverTrigger,
  Image,
  Box,
  PopoverAnchor,
  Portal,
  LightMode,
  Icon,
} from '@/ui';
import { LabelList } from '../LabelList';
import { Badge } from './components/Badge';
import { ButtonWithFilters } from './components/ButtonWithFilters';
import { CategoryButton } from './components/CategoryButton';
import { FeedButton } from './components/FeedButton';
import { Tab } from './components/Tab';
import { TabList } from './components/TabList';
import { TabPanel } from './components/TabPanel';

type Props = {
  pointerEvents?: 'none' | 'auto';
};

export const FilterPopover = ({ pointerEvents }: Props) => {
  const { schedule } = useScheduleContext();
  const {
    filterIds,
    filteredCategories,
    filteredLabels,
    filteredWhoLabels,
    filteredFeeds,
    hasAppliedFilters,
    setFilters,
    clearFilters,
  } = useScheduleFilters();
  const {
    isOpen,
    onOpen: openPopover,
    onClose: closePopover,
    onToggle: togglePopover,
  } = useDisclosure();
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { t } = useTranslation('filterPopover');
  const ref = useRef<HTMLDivElement | null>(null);
  const initialFocusRef = useRef<HTMLElement | null>(null);
  const locationState = useLocation().state as
    | undefined
    | ScheduleLocationState;

  useEffect(() => {
    if (locationState?.openFilterPopover) {
      openPopover();
    }
  }, [locationState, openPopover]);

  if (!schedule) {
    return null;
  }

  const filtersCount =
    filteredCategories?.length +
    filteredLabels?.length +
    filteredWhoLabels?.length +
    filteredFeeds?.length;

  const handleCategoryClick = (category: Category) => {
    const categories = filterIds.categories.includes(category.id)
      ? without(filterIds.categories, category.id)
      : [...filterIds.categories, category.id];
    setFilters({ ...filterIds, categories });
  };

  const handleWhoLabelClick = (label: Label) => {
    const whoLabels = filterIds.whoLabels.includes(label.id)
      ? without(filterIds.whoLabels, label.id)
      : [...filterIds.whoLabels, label.id];
    setFilters({ ...filterIds, whoLabels });
  };

  const handleLabelClick = (label: Label) => {
    const labels = filterIds.labels.includes(label.id)
      ? without(filterIds.labels, label.id)
      : [...filterIds.labels, label.id];
    setFilters({ ...filterIds, labels });
  };

  const handleFeedClick = (feed: { id: string }) => {
    const feeds = filterIds.feeds.includes(feed.id)
      ? without(filterIds.feeds, feed.id)
      : [...filterIds.feeds, feed.id];
    setFilters({ ...filterIds, feeds });
  };

  return (
    <DarkMode>
      <Flex overflow={isMobileBreakpoint ? 'visible' : 'hidden'} ref={ref}>
        <Popover
          gutter={isMobileBreakpoint ? 12 : 8}
          initialFocusRef={initialFocusRef} // Fixes AH-1416
          isLazy
          isOpen={isOpen}
          placement={isMobileBreakpoint ? 'top' : 'bottom-end'}
          returnFocusOnClose={false}
          variant="primary"
          onClose={closePopover}
        >
          <LightMode>
            {hasAppliedFilters && !isMobileBreakpoint ? (
              <PopoverAnchor>
                <ButtonWithFilters
                  onClear={clearFilters}
                  onClick={togglePopover}
                />
              </PopoverAnchor>
            ) : isMobileBreakpoint ? (
              <PopoverTrigger>
                <Button
                  aria-label={t('button_text')}
                  borderRadius="full"
                  isActive={isOpen}
                  pointerEvents={pointerEvents}
                  pos="relative"
                  px="0"
                  variant="secondary"
                  onClick={openPopover}
                >
                  {filtersCount > 0 && <Badge>{filtersCount}</Badge>}
                  <Icon icon="FileSearch" size="18px" />
                </Button>
              </PopoverTrigger>
            ) : (
              <PopoverTrigger>
                <Button
                  isActive={isOpen}
                  leftIcon={<Icon icon="FileSearch" size="18px" />}
                  variant="secondary"
                  onClick={openPopover}
                >
                  {t('button_text')}
                </Button>
              </PopoverTrigger>
            )}
          </LightMode>

          <Portal>
            <PopoverContent
              data-testid="focus-popover"
              pointerEvents={pointerEvents}
              w={{ base: '350px', md: '450px' }}
            >
              <PopoverBody p="0">
                <Tabs isFitted variant="unstyled">
                  <TabList>
                    <Tab icon={<Image src={colorWheel} />}>
                      {t('categories')}
                    </Tab>
                    <Tab icon={<Image mt="-1" src={whoIcon} />}>{t('who')}</Tab>
                    <Tab icon={<Image mt="-0.5" src={labelIcon} />}>
                      {t('labels')}
                    </Tab>
                    <Tab icon={<BsLightningChargeFill />}>{t('sources')}</Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel>
                      <VStack alignItems="flex-start" spacing={2}>
                        {schedule.categories.map((category, index) => {
                          const isSelected = filterIds.categories.includes(
                            category.id
                          );
                          return (
                            <CategoryButton
                              category={category}
                              isSelected={isSelected}
                              key={category.id}
                              ref={index === 0 ? initialFocusRef : undefined}
                              onClick={handleCategoryClick}
                            />
                          );
                        })}
                      </VStack>
                    </TabPanel>

                    <TabPanel>
                      {schedule.whoLabels.length === 0 ? (
                        <Box textAlign="center">{t('empty_who')}</Box>
                      ) : (
                        <LabelList
                          labels={schedule.whoLabels}
                          selected={filteredWhoLabels}
                          onClick={handleWhoLabelClick}
                        />
                      )}
                    </TabPanel>

                    <TabPanel>
                      {schedule.labels.length === 0 ? (
                        <Box textAlign="center">{t('empty_labels')}</Box>
                      ) : (
                        <LabelList
                          labels={schedule.labels}
                          selected={filteredLabels}
                          onClick={handleLabelClick}
                        />
                      )}
                    </TabPanel>

                    <TabPanel>
                      {schedule.feeds.length === 0 ? (
                        <Flex
                          align="center"
                          direction="column"
                          gap="4"
                          justify="center"
                        >
                          {t('empty_sources')}
                          <Button
                            _hover={{ bg: 'dark.900' }}
                            as={RouterLink}
                            bg="dark.600"
                            color="white"
                            leftIcon={<Icon icon="Plus" />}
                            size="sm"
                            to="../sources"
                          >
                            {t('add_source_cta')}
                          </Button>
                        </Flex>
                      ) : (
                        <VStack>
                          {schedule.feeds.map((feed) => {
                            const isSelected = filterIds.feeds.includes(
                              feed.id
                            );
                            return (
                              <FeedButton
                                isSelected={isSelected}
                                key={feed.id}
                                onClick={() => handleFeedClick(feed)}
                              >
                                {feed.title}
                              </FeedButton>
                            );
                          })}
                        </VStack>
                      )}
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      </Flex>
    </DarkMode>
  );
};
