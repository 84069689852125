import { EditEventModal } from './EditEventModal';
import { useEditEventModal } from './useEditEventModal';

export const EditEventModalLoader = () => {
  const { magicEvent } = useEditEventModal();

  if (!magicEvent) {
    return null;
  }

  return <EditEventModal magicEvent={magicEvent} />;
};
