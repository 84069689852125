import type { PageExperienceConfig } from '../types';
import banner from './banner.png';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'first-grade-class-newsletter',
  hidePastEntries: false,
  theme: {
    '--primary-color': '#e14b89',
    '--secondary-color': '#fff7e2',
  },
  header: {
    banner,
    logo,
  },
};
