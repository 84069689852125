import { Flex, type FlexProps } from '@/ui';

export const MenuSection = (props: FlexProps) => {
  return (
    <Flex
      borderRadius="2xl"
      direction="column"
      p="var(--menu-padding)"
      {...props}
    />
  );
};
