import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useEvent from 'react-use-event-hook';
import { useOptimisticValue } from '@/hooks/useOptimisticValue';
import { HiddenIcon } from '@/pages/Schedule/components/HiddenIcon';
import type { DecoratedInstance } from '@/pages/Schedule/types';
import { areInstancesEqual, getEntryItemStyles } from '@/pages/Schedule/utils';
import { Flex, IconButton } from '@/ui';

type Props = {
  instance: DecoratedInstance;
  onClick: (instance: DecoratedInstance) => void;
  onToggleHideEntryClick?: (instance: DecoratedInstance) => void;
};

const Component = ({ instance, onClick, onToggleHideEntryClick }: Props) => {
  const { t } = useTranslation(['common']);
  const [localInstance, setLocalInstance] = useOptimisticValue(instance);
  const canHideItems = !!onToggleHideEntryClick;

  const styles = useMemo(
    () => getEntryItemStyles(localInstance),
    [localInstance]
  );

  const handleToggleHidden = useEvent(() => {
    setLocalInstance({ ...localInstance, isHidden: !localInstance.isHidden });
    onToggleHideEntryClick?.(localInstance);
  });

  return (
    <Flex
      data-group
      data-instance-id={instance.id}
      data-testid="entry-row"
      flex="1"
      key={instance.id}
      pos="relative"
      w="100%"
    >
      <Flex
        className="row"
        key={instance.id}
        role="button"
        {...styles.container}
        onClick={() => onClick(instance)}
      >
        <Flex className="cell date">
          {instance.startDate.toFormat('ccc, MMM dd')}
        </Flex>
        <Flex className="cell emoji">{instance.emoji}</Flex>
        <Flex className="cell title">
          {instance.title || t('common:untitled_entry')}
        </Flex>
        <Flex className="cell time">
          {instance.isOnDay
            ? 'All day'
            : `${instance.startDate.toFormat(
                'h:mm a'
              )} \u2013 ${instance.endDate.toFormat('h:mm a')}`}
        </Flex>
        <Flex className="cell location">
          {instance.locationWithPlace?.name}
        </Flex>
        <Flex className="cell category">{instance.category?.text}</Flex>
      </Flex>

      {canHideItems && (
        <IconButton
          _groupHover={{ opacity: 1 }}
          icon={<HiddenIcon isHidden={instance.isHidden} />}
          opacity="0"
          pos="absolute"
          right="-42px"
          size="sm"
          top="50%"
          transform="translateY(-50%)"
          variant="secondary"
          aria-label={
            instance.isHidden
              ? t('common:unhide_entry')
              : t('common:hide_entry')
          }
          onClick={handleToggleHidden}
        />
      )}
    </Flex>
  );
};

export const EntryTableRow = memo(Component, (prevProps, nextProps) => {
  return (
    prevProps.onClick === nextProps.onClick &&
    prevProps.onToggleHideEntryClick === nextProps.onToggleHideEntryClick &&
    areInstancesEqual(prevProps.instance, nextProps.instance)
  );
});
