import { type SetStateAction, useState } from 'react';
import type { Prompt } from '../types';

const INITIAL_PROMPT: Prompt = {
  html: '',
  file: null,
};

export const usePrompt = () => {
  const [prompt, _setPrompt] = useState<Prompt>(INITIAL_PROMPT);

  const setPrompt = (value: SetStateAction<Partial<Prompt>>) => {
    _setPrompt((prevPrompt) => ({
      ...prevPrompt,
      ...value,
    }));
  };

  const resetPrompt = () => {
    setPrompt(INITIAL_PROMPT);
  };

  return {
    prompt,
    setPrompt,
    resetPrompt,
  };
};
