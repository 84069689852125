import { useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient, queryClient } from '@/lib';
import { MagicJobFragment } from '@/pages/QuickCreate/fragments/MagicJobFragment';
import { createDecoratedMagicJob } from '@/pages/QuickCreate/utils/createDecoratedMagicEvent';
import type { QueryError } from '@/utils/errors';
import { createGetMagicJobsQueryKey } from '@/utils/queryKeys';
import type {
  CompleteMagicJobMutation,
  CompleteMagicJobMutationVariables,
} from './useCompleteMagicJob.generated';

const query = gql`
  ${MagicJobFragment}
  mutation CompleteMagicJob($input: CompleteMagicJobInput!) {
    completeMagicJob(input: $input) {
      ...MagicJob
    }
  }
`;

export const useCompleteMagicJob = () => {
  const { mutate, data, ...rest } = useMutation<
    CompleteMagicJobMutation,
    QueryError,
    CompleteMagicJobMutationVariables
  >({
    mutationFn: (variables) => {
      return gqlClient.request<
        CompleteMagicJobMutation,
        CompleteMagicJobMutationVariables
      >(query, variables);
    },
    onSuccess: (result) => {
      const data = result.completeMagicJob;
      const job = createDecoratedMagicJob(data);
      queryClient.setQueryData(createGetMagicJobsQueryKey(), job);
    },
  });

  const completeMagicJob = useCallback(
    (magicJobId: string, timeZone: string) => {
      mutate({ input: { magicJobId, timeZone } });
    },
    [mutate]
  );

  const job = data ? createDecoratedMagicJob(data.completeMagicJob) : undefined;

  return {
    completeMagicJob,
    job,
    ...rest,
  };
};
