import type { PageExperienceConfig } from '../types';
import banner from './banner.jpg';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'lane-memorial-library',
  theme: {
    '--primary-color': '#00254E',
    '--secondary-color': '#fbf9f4',
  },
  header: {
    href: 'https://hampton.lib.nh.us',
    banner,
    logo,
  },
};
