import {
  Notice,
  NoticeDescription,
  NoticeTitle,
} from '@/pages/QuickCreate/components/Notice';
import { MagicJobStatus } from '@/types/gql.generated';
import type { InvalidEmailJobStatus } from './types';

type Props = {
  status: InvalidEmailJobStatus;
};

export const InvalidEmailJobNotice = ({ status }: Props) => {
  return (
    <Notice maxW="600px">
      {status === MagicJobStatus.Failed ? (
        <NoticeTitle>An error occurred converting your email.</NoticeTitle>
      ) : (
        <>
          <NoticeTitle>Your magic results aren&apos;t ready yet!</NoticeTitle>
          <NoticeDescription>Please check back shortly.</NoticeDescription>
        </>
      )}
    </Notice>
  );
};
