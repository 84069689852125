import type { PageExperienceConfig } from '../types';
import banner from './banner.jpg';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'dover-doula',
  theme: {
    '--primary-color': '#0A4207',
    '--secondary-color': '#fffdfd',
  },
  header: {
    href: 'https://doverdoula.com',
    banner,
    logo,
  },
};
