import type { PageExperienceConfig } from '../types';
import banner from './banner.png';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'library-events-durham-nh',
  theme: {
    '--primary-color': '#07254B',
    '--secondary-color': '#D8E0E6',
  },
  header: {
    href: 'https://www.durhampubliclibrary.org',
    banner,
    logo,
  },
};
