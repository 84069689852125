import type { PageExperienceConfig } from '../types';
import banner from './banner.png';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'hubspot-sales-kickoff',
  addToCalendar: 'email',
  theme: {
    '--primary-color': '#ff5c35',
    '--secondary-color': '#F6F9FC',
  },
  header: {
    href: 'https://www.hubspot.com',
    banner,
    logo,
  },
};
