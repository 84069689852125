import type { PageExperienceConfig } from '../types';
import banner from './banner.png';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'twisted-fate-brewing',
  hidePastEntries: false,
  theme: {
    '--primary-color': '#3F3F3F',
    '--secondary-color': '#f1f1f1',
  },
  header: {
    href: 'https://twistedfatebrewing.com/calendar',
    banner,
    logo,
  },
};
