import type { PageExperienceConfig } from '../types';
import banner from './banner.jpg';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'new-parent-checklist',
  hidePastEntries: false,
  theme: {
    '--primary-color': '#43002c',
    '--secondary-color': '#EEE9E4',
  },
  header: {
    banner,
    logo,
  },
};
