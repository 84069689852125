import type { PageExperienceConfig } from '../types';
import banner from './banner.jpg';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'seacoast-outright',
  hidePastEntries: false,
  theme: {
    '--primary-color': '#271F5E',
    '--secondary-color': '#E3ECF7',
  },
  header: {
    href: 'https://www.seacoastoutright.org',
    banner,
    logo,
  },
};
