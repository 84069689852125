import { useEffect } from 'react';
import { useUnmount } from 'react-use';
import { useDebounceLoading } from '@/hooks/useDebounceLoading';
import { useRequestAccess } from '@/hooks/useRequestAccess';
import { Feature } from '@/types/gql.generated';
import { Icon, Button, Flex, useToast, Spinner } from '@/ui';
import { useRequestCustomColors } from './useRequestCustomColors';

export const RequestCustomColors = () => {
  const { requestAccess, isPending } = useRequestAccess();
  const toast = useToast();
  const { requested, hidden, setRequested, setHidden } =
    useRequestCustomColors();

  const handleClick = () => {
    requestAccess(Feature.CustomColors, {
      onSuccess: () => setRequested(),
      onError: () => toast.error('Error requesting access to custom colors'),
    });
  };

  const debouncedIsPending = useDebounceLoading(isPending);

  useUnmount(() => {
    if (requested && !hidden) {
      setHidden();
    }
  });

  useEffect(() => {
    if (requested) {
      const timerId = setTimeout(setHidden, 20_000);
      return () => clearTimeout(timerId);
    }
  }, [requested, setHidden]);

  return (
    <Flex align="center" color="gray.600" fontSize="sm" justify="center">
      {debouncedIsPending ? (
        <Flex align="center" gap="2">
          <Spinner size="sm" />
          Requesting access...
        </Flex>
      ) : requested ? (
        <>
          <Icon color="purple.600" icon="Heart" mr="1.5" />
          Thanks! We&apos;ll be in touch.
        </>
      ) : (
        <>
          <Icon color="hsla(40, 100%, 58%, 1)" icon="Crown" mr="1.5" />
          <Button
            _hover={{ color: 'currentColor' }}
            color="currentColor"
            display="inline"
            fontWeight="normal"
            mr="1"
            textDecor="underline"
            variant="link"
            onClick={handleClick}
          >
            Request
          </Button>
          custom colors
        </>
      )}
    </Flex>
  );
};
