import type { PageExperienceConfig } from '../types';
import banner from './banner.jpg';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'bow-nh-events',
  hidePastEntries: true,
  theme: {
    '--primary-color': '#1A2A55',
    '--secondary-color': '#D8E5ED',
  },
  header: {
    href: 'https://bownh.gov/calendar.aspx',
    banner,
    logo,
  },
};
