import type { ChangeEvent } from 'react';
import { TextareaAutosize } from '@/components/TextareaAutosize';
import { Box, type BoxProps } from '@/ui';

type Props = Omit<BoxProps, 'onChange'> & {
  value: string;
  placeholder?: string;
  autoFocus?: boolean;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onEnterPress: () => void;
};

export const TitleInput = ({
  id,
  value,
  autoFocus,
  placeholder,
  onChange,
  onEnterPress,
  ...props
}: Props) => {
  return (
    <Box w="100%" {...props}>
      <TextareaAutosize
        autoFocus={autoFocus}
        id={id}
        placeholder={placeholder}
        value={value}
        style={{
          width: '100%',
          outline: 'none',
          fontSize: 'var(--chakra-fontSizes-xl)',
          fontWeight: 'bold',
          lineHeight: 1.2,
        }}
        onChange={onChange}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            onEnterPress();
          }
        }}
      />
    </Box>
  );
};
