import type { PageExperienceConfig } from '../types';
import banner from './banner.jpg';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'saltwater-doulas',
  hidePastEntries: false,
  theme: {
    '--primary-color': '#85a09e',
    '--secondary-color': '#F5F3F1',
  },
  header: {
    href: 'https://saltwaterdoulanh.com',
    banner,
    logo,
  },
};
