import { darken } from 'color2k';
import { useParams } from 'react-router-dom';
import { validate as validateUuid } from 'uuid';
import { useAnalytics } from '@/hooks/useAnalytics';
import { Card, CardBody } from '@/pages/QuickCreate/components/Card';
import { EntryList } from '@/pages/QuickCreate/components/EntryList';
import { Heading } from '@/pages/QuickCreate/components/Heading';
import { useQuickCreateContext } from '@/pages/QuickCreate/contexts';
import { Box, Image, Link, Text } from '@/ui';
import { PublicFeedbackForm } from './PublicFeedbackForm';

export const Details = () => {
  const { trackEvent } = useAnalytics();
  const { job, page } = useQuickCreateContext();
  const { slug } = useParams<{ slug: string }>();

  const canLeaveFeedback = slug && !validateUuid(slug);
  const hasTitleOrDescription = !!(page?.title || page?.description);

  return (
    <>
      <Card
        bg="rgba(255, 255, 255, 0.50)"
        border="1px solid rgba(0, 0, 0, 0.2)"
        flexDir="column"
        mb="4"
        overflow="hidden"
      >
        {page?.image && <Image src={page.image} />}

        {hasTitleOrDescription && (
          <CardBody flexDir="column" gap="3" py={{ base: 3, md: 5 }}>
            {page?.title && (
              <Heading fontSize={{ base: 'xl', sm: '2xl' }}>
                {page.title}
              </Heading>
            )}
            {page?.description && (
              <Text
                dangerouslySetInnerHTML={{ __html: page.description }}
                sx={{
                  a: {
                    color: 'customgray.dark',
                    textDecoration: 'underline',
                  },
                }}
              />
            )}
            {canLeaveFeedback && (
              <PublicFeedbackForm mt={hasTitleOrDescription ? 1 : 0} />
            )}
          </CardBody>
        )}
      </Card>

      <EntryList />

      <Box
        fontSize="lg"
        fontWeight="bold"
        mt="8"
        mx="auto"
        px={{ base: 4, sm: 0 }}
        textAlign="center"
      >
        Use our{' '}
        <Link
          color={darken('#E7E3F5', 0.4)}
          colorScheme="dark"
          href="/magic"
          ml="1"
          target="_blank"
          textDecor="underline"
          onClick={() =>
            trackEvent('quick-create:click details cta', {
              jobId: job?.id || 'unknown',
            })
          }
        >
          AI magic wand 🪄
        </Link>{' '}
        to get content on your calendar today.
      </Box>
    </>
  );
};
