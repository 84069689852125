import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient } from '@/lib';
import type { QueryError } from '@/utils/errors';
import type {
  DeleteMagicEventMutation,
  DeleteMagicEventMutationVariables,
} from './useDeleteMagicEvent.generated';

const query = gql`
  mutation DeleteMagicEvent($input: DeleteEventInput!) {
    deleteEvent(input: $input)
  }
`;

export const useDeleteMagicEvent = () => {
  const { mutate, isPending, reset } = useMutation<
    DeleteMagicEventMutation,
    QueryError,
    DeleteMagicEventMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        DeleteMagicEventMutation,
        DeleteMagicEventMutationVariables
      >(query, variables),
  });

  const deleteMagicEvent = useCallback(
    (
      eventId: string,
      options?: MutateOptions<
        DeleteMagicEventMutation,
        QueryError,
        DeleteMagicEventMutationVariables
      >
    ) => mutate({ input: { eventId } }, options),
    [mutate]
  );

  return {
    deleteMagicEvent,
    isPending,
    reset,
  };
};
