import type { PageExperienceConfig } from '../types';
import banner from './banner.jpg';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'glassbox-new-customer-onboarding',
  hidePastEntries: false,
  theme: {
    '--primary-color': '#ed38ab',
    '--secondary-color': '#F5F3F1',
  },
  header: {
    href: 'https://www.glassbox.com',
    banner,
    logo,
  },
};
