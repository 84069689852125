import { autoUpdate, offset, shift, useFloating } from '@floating-ui/react';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { Icon, IconButton, type Icons } from '@/ui';
import { ControlTooltip } from './ControlTooltip';

type Props = {
  isOpen?: boolean;
  controlId: string;
  icon: JSX.Element | keyof typeof Icons;
  label: string;
  menu?: JSX.Element;
  onClick: () => void;
};

export const Control = ({
  controlId,
  isOpen,
  icon,
  label,
  menu,
  onClick,
}: Props) => {
  const { trackEvent } = useAnalytics();
  const isMobileBreakpoint = useIsMobileBreakpoint();

  const { floatingStyles, refs } = useFloating({
    strategy: 'fixed',
    placement: isMobileBreakpoint ? 'top' : 'left-start',
    whileElementsMounted: autoUpdate,
    middleware: isMobileBreakpoint
      ? [offset({ mainAxis: 16, crossAxis: 0 }), shift({ padding: 12 })]
      : [offset({ mainAxis: 16, crossAxis: -20 }), shift({ padding: 20 })],
  });

  return (
    <>
      <ControlTooltip isDisabled={isOpen} label={label}>
        <IconButton
          _active={{ bg: 'purple.50' }}
          _focusVisible={{ boxShadow: 'outline' }}
          _hover={{ opacity: 1, bg: 'purple.50' }}
          aria-label={label}
          borderRadius="var(--control-border-radius)"
          boxSize="var(--control-box-size)"
          isActive={isOpen}
          opacity={isOpen ? 1 : 0.5}
          ref={refs.setReference}
          variant="ghost"
          icon={
            typeof icon === 'string' ? <Icon icon={icon} size="28px" /> : icon
          }
          onClick={() => {
            trackEvent('experience:author click edit-control', {
              control: controlId,
            });
            onClick();
          }}
        />
      </ControlTooltip>

      {isOpen && (
        <div ref={refs.setFloating} style={floatingStyles}>
          {menu}
        </div>
      )}
    </>
  );
};
