import type { DecoratedIntegration } from '@/pages/Schedule/hooks/useIntegrations';
import { PlatformTypes } from '@/types/gql.generated';
import { hasGoogleIntegration } from './googleCalendar';

export const getName = (platform: PlatformTypes): string => {
  if (platform === PlatformTypes.AgendaHero) {
    return 'Agenda Hero';
  }
  if (platform === PlatformTypes.AppleIcs) {
    return 'Apple';
  }
  if (platform === PlatformTypes.Email) {
    return 'Email';
  }
  if (
    platform === PlatformTypes.GoogleIntegration ||
    platform === PlatformTypes.GoogleLink
  ) {
    return 'Google';
  }
  if (platform === PlatformTypes.OfficeLink) {
    return 'Office 365';
  }
  if (platform === PlatformTypes.OutlookLink) {
    return 'Outlook';
  }

  return 'Other';
};

export const isPinnable = (platform: PlatformTypes): boolean => {
  return platform !== PlatformTypes.AgendaHero;
};

export const isPlatformType = (value: unknown): value is PlatformTypes => {
  return Object.values(PlatformTypes).includes(value as PlatformTypes);
};

export const ensurePlatform = (
  platform: PlatformTypes,
  integrations: DecoratedIntegration[]
): PlatformTypes => {
  if (
    platform === PlatformTypes.GoogleIntegration ||
    platform === PlatformTypes.GoogleLink
  ) {
    // Use integration if available, else fall back to link
    return hasGoogleIntegration(integrations)
      ? PlatformTypes.GoogleIntegration
      : PlatformTypes.GoogleLink;
  }
  return platform;
};

export const getPlatformAddUrl = (
  eventId: string,
  platform: PlatformTypes,
  isMobile: boolean,
  experienceId?: string
): string | null => {
  const platformMap: Partial<Record<PlatformTypes, string>> = {
    [PlatformTypes.GoogleLink]: 'google',
    [PlatformTypes.OutlookLink]: 'outlook',
    [PlatformTypes.OfficeLink]: 'office',
    [PlatformTypes.AppleIcs]: 'apple',
    [PlatformTypes.OtherIcs]: 'other',
  };

  if (!platformMap[platform]) {
    return null;
  }

  const params = new URLSearchParams();
  params.append('eventId', eventId);
  params.append('device', isMobile ? 'mobile' : 'desktop');
  params.append('platform', platformMap[platform]);
  if (experienceId) {
    params.append('experienceId', experienceId);
  }

  return `/event/weblink?${params.toString()}`;
};
