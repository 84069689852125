import { useConditionallyMount } from '@/hooks/useConditionallyMount';
import { AddContentModal } from './AddContentModal';
import { useAddContentModalStore } from './store';

export const AddContentModalLoader = () => {
  const { isOpen } = useAddContentModalStore();
  const { mounted, setMounted } = useConditionallyMount(isOpen);

  if (!mounted) {
    return null;
  }

  return <AddContentModal onCloseComplete={() => setMounted(false)} />;
};
