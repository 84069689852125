import { useEffect, useRef, useState } from 'react';
import { MotionBox, type MotionBoxProps, Box, type BoxProps } from '@/ui';

type Props = MotionBoxProps & {
  wrapperProps?: BoxProps;
  enabled?: boolean;
};

export const AnimateChangeInHeight = ({
  children,
  wrapperProps,
  enabled = true,
  ...props
}: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<number | 'auto'>('auto');

  useEffect(() => {
    if (containerRef.current && enabled) {
      const resizeObserver = new ResizeObserver((entries) => {
        const observedHeight = entries[0].contentRect.height;
        setHeight(observedHeight);
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [enabled]);

  return (
    <MotionBox
      animate={{ height }}
      height={height}
      transition={{ duration: 0.15 }}
      {...props}
    >
      <Box ref={containerRef} {...wrapperProps}>
        {children}
      </Box>
    </MotionBox>
  );
};
