import type { PageExperienceConfig } from '../types';
import banner from './banner.png';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'great-american-beer-hall-events',
  hidePastEntries: true,
  isLive: true,
  theme: {
    '--primary-color': '#022D61',
    '--secondary-color': '#f1f1f1',
  },
  header: {
    href: 'https://www.gabhall.com/events',
    banner,
    logo,
  },
};
