import { Route, Routes } from 'react-router-dom';
import { useCampaignTracking } from '@/hooks/useCampaignTracking';
import { MaintenanceMode } from './components/MaintenanceMode';
import { useIntercom } from './hooks/useIntercom';
import { Home } from './pages/Home';
import { JobDetail } from './pages/JobDetail';

export const QuickCreate = () => {
  useCampaignTracking();
  useIntercom();

  return (
    <MaintenanceMode>
      <Routes>
        <Route element={<Home />} index />
        <Route element={<JobDetail />} path="s/:slug" />
        <Route element={<JobDetail />} path="p/:slug" />
      </Routes>
    </MaintenanceMode>
  );
};
