import { Flex, forwardRef, type FlexProps } from '@/ui';

export const Container = forwardRef<FlexProps, typeof Flex>(
  function ContainerWithRef(props, ref) {
    return (
      <Flex
        bg="white"
        borderRadius="2xl"
        bottom={{ base: 8, md: 'auto' }}
        direction={{ base: 'row', md: 'column' }}
        gap="2"
        left={{ base: '50%', md: 'auto' }}
        p="2"
        pos="fixed"
        ref={ref}
        right={{ md: 8 }}
        shadow="0px 0px 14px 0px rgba(0, 0, 0, 0.15)"
        top={{ md: '50%' }}
        transform={{ base: 'translateX(-50%)', md: 'translateY(-50%)' }}
        zIndex="1"
        {...props}
      />
    );
  }
);
