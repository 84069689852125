import type { PageExperienceConfig } from '../types';
import banner from './banner.png';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'summer-concert-series',
  hidePastEntries: false,
  theme: {
    '--primary-color': '#4D9E2C',
    '--secondary-color': '#f7f7f7',
  },
  header: {
    href: 'https://friendsofthepublicgarden.org/summer-concert-series/',
    banner,
    logo,
  },
};
