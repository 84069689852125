import type { PageExperienceConfig } from '../types';
import banner from './banner.png';
import logo from './logo.png';

export const config: PageExperienceConfig = {
  slug: 'miss-stones-important-events',
  hidePastEntries: false,
  theme: {
    '--primary-color': '#0a9cdd',
    '--secondary-color': '#f4f9ff',
  },
  header: {
    banner,
    logo,
  },
};
