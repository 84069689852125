import Intercom, { shutdown, update } from '@intercom/messenger-js-sdk';
import { useEffect } from 'react';
import { config } from '@/config';
import { useCurrentUserContext } from '@/contexts/CurrentUserContext';

export const useIntercom = () => {
  const { currentUser } = useCurrentUserContext();

  // show/hide the widget only on the magic route
  useEffect(() => {
    if (config.intercom.appId) {
      Intercom({ app_id: config.intercom.appId });
      return () => shutdown();
    }
  }, []);

  // set user attributes
  useEffect(() => {
    if (!config.intercom.appId) {
      return;
    }

    const props: Record<string, string | undefined> = {};

    if (currentUser) {
      props.user_id = currentUser.id;
      if (currentUser.email) {
        props.email = currentUser.email;
      }
      if (currentUser.name) {
        props.name = currentUser.name;
      }
    }

    update(props);
  });
};
